import React, { useCallback, useState, useEffect } from 'react';

import { FiEdit3, FiXCircle } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  ContentArea,
  Section,
  FormContainer,
  ProcessTypeItemList,
} from './styles';

import api from '../../services/api';

import ConfigurationMenu from '../../components/ConfigurationMenu';
import Button from '../../components/Button';

interface ProcessType {
  id: string;
  name: string;
}

const ProcessType: React.FC = () => {
  const history = useHistory();

  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);

  useEffect(() => {
    api.get('/process-types').then((response) => {
      setProcessTypes(response.data);
    });
  }, []);

  const handleCallNewProcessTypeView = useCallback(() => {
    history.push('/process-type-form');
  }, [history]);

  const handleDeleteProcessType = useCallback(async (processId: string) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      await api.delete(`/process-types/${processId}`).then((response) => {
        setProcessTypes((proc) =>
          proc.filter((_proc) => _proc.id !== processId),
        );
      });
    }
  }, []);

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Tipos de Processo</h1>
          <FormContainer>
            <Button type="button" onClick={handleCallNewProcessTypeView}>
              Novo
            </Button>
          </FormContainer>

          <Section>
            {processTypes.map((processType) => (
              <ProcessTypeItemList key={processType.id}>
                <div>
                  <strong>{processType.name}</strong>
                </div>

                <div>
                  <Link
                    to={{
                      pathname: '/process-type-form',
                      state: processType,
                    }}
                    title="Editar"
                  >
                    <FiEdit3 />
                  </Link>

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      processType.id && handleDeleteProcessType(processType.id);
                    }}
                  />
                </div>
              </ProcessTypeItemList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ProcessType;
