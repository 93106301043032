/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FiSearch, FiArrowLeft } from 'react-icons/fi';
import { useLocation, Link } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Container,
  Content,
  ContentArea,
  FormContainer,
  BackContainer,
} from './styles';

import api from '../../../services/api';

import LeftMenu from '../../../components/LeftMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

interface AttendanceType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  selected: boolean;
}

interface Client {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
  folder: string;
}

interface Responsible {
  user_id: string;
}

interface IntimationFormData {
  id: string;
  client_process_id: string;
  attendance_type_id: string;
  description: string;
  deadline: string;
  responsibles: Responsible[];
  clientProcess: ClientProcess;
  description_finalization: string;
  user?: User;
}

interface LocationState {
  intimation: IntimationFormData;
}

const FinishIntimation: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const locationState: LocationState = location.state as LocationState;

  // console.log('intimationToUpdate', locationState.intimation);

  const { addToast } = useToast();

  const [attendanceTypes, setAttendanceTypes] = useState<AttendanceType[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [clientProcess, setClientProcess] = useState<ClientProcess>();

  const selectedUsers: string[] = [];
  const alreadySelectedUserIds: string[] = [];

  useEffect(() => {
    if (locationState.intimation) {
      setClientProcess(locationState.intimation.clientProcess);

      formRef.current?.setData({
        folder: locationState.intimation.clientProcess.folder,
      });
      formRef.current?.setData({
        attendance_type_id: locationState.intimation.attendance_type_id,
      });
      formRef.current?.setData({
        deadline: locationState.intimation.deadline,
      });
      formRef.current?.setData({
        description: locationState.intimation.description,
      });

      locationState.intimation.responsibles.map((responsible) =>
        alreadySelectedUserIds.push(responsible.user_id),
      );

      locationState.intimation.responsibles.map((responsible) =>
        selectedUsers.push(responsible.user_id),
      );
    }
  }, [alreadySelectedUserIds, locationState.intimation, selectedUsers]);

  useEffect(() => {
    api.get('/attendance-types').then((response) => {
      setAttendanceTypes(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/users').then((response) => {
      const userFormatted = response.data.map((item: User) => {
        return {
          ...item,
          selected: alreadySelectedUserIds.includes(item.id),
        };
      });

      setUsers(userFormatted);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxClick = useCallback(
    (user_id: string) => {
      const exists = selectedUsers.includes(user_id);
      const elementsIndex = users.findIndex(
        (element) => element.id === user_id,
      );
      const newArray = [...users];

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        selected: !newArray[elementsIndex].selected,
      };
      setUsers(newArray);

      if (!exists) {
        selectedUsers.push(user_id);
      } else {
        const index = selectedUsers.indexOf(user_id);
        if (index > -1) {
          selectedUsers.splice(index, 1);
        }
      }
    },
    [selectedUsers, users],
  );

  const handleFindByFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    if (folder) {
      api.get(`/client-process/folder/${folder}`).then((response) => {
        if (response.data) {
          setClientProcess(response.data);
        } else {
          setClientProcess(undefined);
          addToast({
            type: 'info',
            title: 'Pasta não encontrada',
            description: 'Não foi possível localizar a pasta informada.',
          });
        }
      });
    }
  }, [addToast]);

  // const concludedImplantationId = 'b186344f-a2ee-400f-9a8e-337bd27c338c';

  const handleSubmit = useCallback(
    async (data: IntimationFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          folder: Yup.string().required('Pasta obrigatória'),
          deadline: Yup.string().required('Prazo obrigatório'),
          attendance_type_id: Yup.string().required('Tipo é obrigat[orio'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (clientProcess) {
          // eslint-disable-next-line no-param-reassign
          data.responsibles = [];
          // eslint-disable-next-line no-param-reassign
          data.client_process_id = clientProcess.id;

          users.forEach((user) => {
            if (user.selected) {
              data.responsibles.push({ user_id: user.id });
            }
          });

          console.log(data);

          // user_id: quem deu o check
          // check_user_id: querm criou o registro

          await api.post('/process-attendance', {
            client_id: clientProcess.client.id,
            attendance_type_id: data.attendance_type_id,
            client_process_id: clientProcess?.id,
            description: data.description_finalization,
            attendance_date: format(new Date(), " yyyy'-'MM'-'dd", {
              locale: ptBR,
            }),
            user_id: user.id,
            check_user_id: locationState.intimation.user?.id,
          });

          await api.delete(`/intimation/${locationState.intimation.id}`);

          // await api.post('/intimation', data);

          history.push('/intimation');

          addToast({
            type: 'success',
            title: 'Finalizada com sucesso!',
            description: 'Intimação/tarefa finalizada',
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso carregar o processo do cliente',
            description: 'Digite a pasta e clique na lupa.',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao finalizar intimação/tarefa.',
        });
      }
    },
    [addToast, 
      clientProcess, 
      history, 
      locationState, 
      users, 
      user.id,]
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Finalização de Intimação/Tarefa</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <BackContainer>
              <Link to="/intimation">
                <FiArrowLeft
                  style={{ marginTop: 15 }}
                  size={30}
                  title="Voltar"
                />
              </Link>
            </BackContainer>

            <FormContainer>
              <InputWithLabel
                name="folder"
                label="Pasta"
                type="text"
                disabled
              />
              <Button
                type="button"
                title="Dados do cliente"
                onClick={handleFindByFolder}
                disabled
              >
                <FiSearch size={30} />
              </Button>
              <label className="process-client">
                {clientProcess && clientProcess?.client.name}{' '}
                {clientProcess && ' - Processo: '}
                {clientProcess && clientProcess.process_number}
              </label>
            </FormContainer>

            <FormContainer>
              <div>
                <Select
                  name="attendance_type_id"
                  label="Tipo de andamento"
                  initialLabel="Selecione o tipo de andamento"
                  options={attendanceTypes.map((attendanceType) => ({
                    value: attendanceType.id,
                    label: attendanceType.name,
                  }))}
                  disabled
                />
              </div>

              <div>
                <InputWithLabel
                  name="deadline"
                  label="Prazo"
                  placeholder="Prazo"
                  type="date"
                  disabled
                />
              </div>
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Descrição"
                rows={2}
                cols={100}
                disabled
              />
            </FormContainer>

            <FormContainer>
              <h2>Responsáveis</h2>
            </FormContainer>

            <FormContainer>
              <ul>
                {users.map((user) => (
                  <li key={user.id}>
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={user.selected}
                      onChange={() => {
                        user && handleCheckboxClick(user.id);
                      }}
                      disabled
                    />
                    <span>{user.name}</span>
                  </li>
                ))}
              </ul>
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description_finalization"
                label="Histórico"
                rows={3}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Finalizar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default FinishIntimation;
