import React, { useRef, useCallback, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { FiArrowLeft } from 'react-icons/fi';

import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

// import api from '../../services/api';

import { Container, Content, ContentArea, FormContainer } from './styles';

import ConfigurationMenu from '../../components/ConfigurationMenu';
import InputWithLabel from '../../components/InputWithLabel';
import Button from '../../components/Button';
import api from '../../services/api';

interface Competence {
  id: string;
  name: string;
}

interface ProcessType {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  folder: string | undefined;
  process_type_id: string | undefined;
  competence_id: string | undefined;
  process_number: string | undefined;
  processType: ProcessType | undefined;
  competence: Competence | undefined;
}

interface Client {
  id: string;
  name: string;
}

interface LocationState {
  process: ClientProcess;
  client: Client;
}

interface ProcessFormData {
  actual_folder: string;
  new_folder: string;
}

const InactivateProcess: React.FC = () => {
  const location = useLocation();

  console.log('Location', location.state);
  const locationState: LocationState = location.state as LocationState;
  const [clientProcessId, setClientProcessId] = useState<string>('');
  const [client, setClient] = useState<Client>();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (locationState.process.id) {
      setClientProcessId(locationState.process.id);
      setClient(locationState.client);
      formRef.current?.setData({ folder: locationState.process.folder });
    }
  }, [locationState.client, locationState.process]);

  const handleSubmit = useCallback(
    async (data: ProcessFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          new_folder: Yup.string().required('Nova pasta obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .put(
            `/client-process/inactivate/${clientProcessId}/${data.new_folder}`,
          )
          .then(() => {
            addToast({
              type: 'success',
              title: 'Processo inativado',
              description: 'Processo inativado com sucesso',
            });
          });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao inativar processo.',
        });
      }
    },
    [addToast, clientProcessId],
  );

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Inativar Processo</h1>

          <FormContainer>
            <Link
              to={{
                pathname: '/inactivate-client',
                state: { client },
              }}
            >
              <FiArrowLeft size={30} title="Cadastro do Cliente" />
            </Link>
          </FormContainer>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <InputWithLabel
                name="folder"
                label="Pasta atual"
                readOnly
                disabled
              />
            </FormContainer>

            <FormContainer>
              <InputWithLabel name="new_folder" label="Nova pasta" autoFocus />
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default InactivateProcess;
