import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  ContentArea,
  Section,
  ClientItemList,
  FormContainer,
} from './styles';

import api from '../../services/api';

import UtilMenu from '../../components/UtilMenu';
import Button from '../../components/Button';

interface User {
  id: string;
  name: string;
  email: string;
}

const UserList: React.FC = () => {
  const history = useHistory();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data);
    });
  }, []);

  const handleCallNewUserView = useCallback(() => {
    history.push('/user-form');
  }, [history]);

  return (
    <Container>
      <Content>
        <UtilMenu />
        <ContentArea>
          <h1>Listagem de Usuários</h1>
          <FormContainer>
            <Button type="button" onClick={handleCallNewUserView}>
              Novo
            </Button>
          </FormContainer>

          <Section>
            {users.map((selectedUser) => (
              <ClientItemList key={selectedUser.id}>
                <div>
                  <strong>{selectedUser.name}</strong>
                </div>

                <div>
                  <strong>{selectedUser.email}</strong>
                </div>

                {/* <div>
                  <Link
                    to={{
                      pathname: '/update-user',
                      state: { selectedUser },
                    }}
                    title="Editar"
                  >
                    <FiEdit3 />
                  </Link>
                </div> */}
              </ClientItemList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default UserList;
