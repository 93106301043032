/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { FiSearch, FiArrowLeft } from 'react-icons/fi';
import { useLocation, Link } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useAuth } from '../../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Container,
  Content,
  ContentArea,
  FormContainer,
  BackContainer,
} from './styles';

import api from '../../../services/api';

import LeftMenu from '../../../components/LeftMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

interface AttendanceType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  selected: boolean;
}

interface Client {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
  folder: string;
}

interface Responsible {
  user_id: string;
}

interface IntimationFormData {
  id: string;
  client_process_id: string;
  attendance_type_id: string;
  description: string;
  deadline: string;
  responsibles: Responsible[];
  clientProcess: ClientProcess;
  user_id: string;
  update_user_id: string;
}

interface LocationState {
  intimation: IntimationFormData;
}

const UpdateIntimation: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  const locationState: LocationState = location.state as LocationState;

  // console.log('intimationToUpdate', locationState.intimation);

  const { addToast } = useToast();

  const [attendanceTypes, setAttendanceTypes] = useState<AttendanceType[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [clientProcess, setClientProcess] = useState<ClientProcess>();

  const selectedUsers: string[] = [];
  const alreadySelectedUserIds: string[] = [];

  useEffect(() => {
    if (locationState.intimation) {
      setClientProcess(locationState.intimation.clientProcess);

      formRef.current?.setData({
        folder: locationState.intimation.clientProcess.folder,
      });
      formRef.current?.setData({
        attendance_type_id: locationState.intimation.attendance_type_id,
      });
      formRef.current?.setData({
        deadline: locationState.intimation.deadline,
      });
      formRef.current?.setData({
        description: locationState.intimation.description,
      });

      locationState.intimation.responsibles.map((responsible) =>
        alreadySelectedUserIds.push(responsible.user_id),
      );

      locationState.intimation.responsibles.map((responsible) =>
        selectedUsers.push(responsible.user_id),
      );
    }
  }, [alreadySelectedUserIds, locationState.intimation, selectedUsers]);

  useEffect(() => {
    api.get('/attendance-types').then((response) => {
      setAttendanceTypes(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/users').then((response) => {
      const userFormatted = response.data.map((item: User) => {
        return {
          ...item,
          selected: alreadySelectedUserIds.includes(item.id),
        };
      });

      setUsers(userFormatted);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxClick = useCallback(
    (user_id: string) => {
      const exists = selectedUsers.includes(user_id);
      const elementsIndex = users.findIndex(
        (element) => element.id === user_id,
      );
      const newArray = [...users];

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        selected: !newArray[elementsIndex].selected,
      };
      setUsers(newArray);

      if (!exists) {
        selectedUsers.push(user_id);
      } else {
        const index = selectedUsers.indexOf(user_id);
        if (index > -1) {
          selectedUsers.splice(index, 1);
        }
      }
    },
    [selectedUsers, users],
  );

  const handleFindByFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    if (folder) {
      api.get(`/client-process/folder/${folder}`).then((response) => {
        if (response.data) {
          setClientProcess(response.data);
        } else {
          setClientProcess(undefined);
          addToast({
            type: 'info',
            title: 'Pasta não encontrada',
            description: 'Não foi possível localizar a pasta informada.',
          });
        }
      });
    }
  }, [addToast]);

  const handleSubmit = useCallback(
    async (data: IntimationFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          folder: Yup.string().required('Pasta obrigatória'),
          deadline: Yup.string().required('Prazo obrigatório'),
          attendance_type_id: Yup.string().required('Tipo é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (clientProcess) {
          // eslint-disable-next-line no-param-reassign
          data.responsibles = [];

          data.client_process_id = clientProcess.id;
          data.user_id = locationState.intimation.user_id;
          data.update_user_id = user.id;

          users.forEach((user) => {
            if (user.selected) {
              data.responsibles.push({ user_id: user.id });
            }
          });

          await api.delete(`/intimation/${locationState.intimation.id}`);

          await api.post('/intimation', data);

          history.push('/intimation');

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Intimação/tarefa criada',
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso carregar o processo do cliente',
            description: 'Digite a pasta e clique na lupa.',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao cadastrar intimação/tarefa.',
        });
      }
    },
    [addToast, clientProcess, history, locationState.intimation.id, users, user.id, 
      locationState.intimation.user_id],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Edição de Prazo</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <BackContainer>
              <Link to="/intimation">
                <FiArrowLeft
                  style={{ marginTop: 15 }}
                  size={30}
                  title="Voltar"
                />
              </Link>
            </BackContainer>
            <FormContainer>
              <InputWithLabel name="folder" label="Pasta" type="text" />
              <Button
                type="button"
                title="Dados do cliente"
                onClick={handleFindByFolder}
              >
                <FiSearch size={30} />
              </Button>
              <label className="process-client">
                {clientProcess && clientProcess?.client.name}{' '}
                {clientProcess && ' - Processo: '}
                {clientProcess && clientProcess.process_number}
              </label>
            </FormContainer>

            <FormContainer>
              <div>
                <Select
                  name="attendance_type_id"
                  label="Tipo de andamento"
                  initialLabel="Selecione o tipo de andamento"
                  options={attendanceTypes.map((attendanceType) => ({
                    value: attendanceType.id,
                    label: attendanceType.name,
                  }))}
                />
              </div>

              <div>
                <InputWithLabel
                  name="deadline"
                  label="Prazo"
                  placeholder="Prazo"
                  type="date"
                />
              </div>
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Descrição"
                rows={3}
                cols={100}
              />
            </FormContainer>

            <FormContainer>
              <h2>Responsáveis</h2>
            </FormContainer>

            <FormContainer>
              <ul>
                {users.map((user) => (
                  <li key={user.id}>
                    {/* <input type="checkbox" defaultChecked={this.state.chkbox} onChange={this.handleChangeChk} /> */}
                    <input
                      className="checkbox"
                      type="checkbox"
                      checked={user.selected}
                      onChange={() => {
                        user && handleCheckboxClick(user.id);
                      }}
                    />
                    <span>{user.name}</span>
                  </li>
                ))}
              </ul>
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default UpdateIntimation;
