/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer } from './styles';

import api from '../../../services/api';

import ConfigurationMenu from '../../../components/ConfigurationMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import Button from '../../../components/Button';

interface AttendanceTypeFormData {
  id: string;
  name: string;
}

interface Location {
  id: string;
  name: string;
}

const AttendanceTypeForm: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const locationState: Location = location.state as Location;

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (locationState) {
      formRef.current?.setData({ name: locationState.name });
    }
  }, [locationState]);

  const handleSubmit = useCallback(
    async (data: AttendanceTypeFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!locationState) {
          await api.post('/attendance-types', {
            name: data.name,
          });
        } else {
          await api.put('/attendance-types', {
            id: locationState.id,
            name: data.name,
          });
        }

        history.push('/attendance-type');

        addToast({
          type: 'success',
          title: !locationState ? 'Cadastro realizado!' : 'Cadastro alterado',
          description: !locationState
            ? 'Novo tipo de andamento criado'
            : 'Tipo de Andamento alterado',
        });
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao cadastrar tipo de andamento.',
        });
      }
    },
    [addToast, history, locationState],
  );

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Cadastro de Tipo de Andamento</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <InputWithLabel name="name" label="Nome" />
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default AttendanceTypeForm;
