import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.main`
  /* max-width: 1120px; */

  display: flex;
`;

export const ContentArea = styled.main`
  /* position: absolute; */
  height: 100vh;

  margin-left: 250px;

  h1 {
    margin-top: 40px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex: 1;
  width: 1000px;
  justify-content: center;
  align-items: center;

  input {
    width: 960px;
  }

  button {
    margin-top: 60px;
  }
`;
