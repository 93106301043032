/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { isBefore, parseISO, format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { FiEdit3, FiXCircle, FiCheckSquare } from 'react-icons/fi';

import {
  Container,
  Content,
  ContentArea,
  Section,
  MyInssList,
  DerContainer,
  FormContainer,
} from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';

import LeftMenu from '../../../components/LeftMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import Button from '../../../components/Button';

interface Client {
  id: string;
  name: string;
  cpf: string;
  password: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
}

interface ProcessAttendance {
  id: string;
  description: string;
}

interface MyInss {
  id: string;
  der: string;
  client: Client;
  client_process: ClientProcess;
  created_at: Date;
  creationDate: string;
  formattedDer: string;
  expiredDer: boolean;
  process_attendance: ProcessAttendance;
}

interface FormData {
  der: Date;
}

const MyInss: React.FC = () => {
  const [myInssList, setMyInssList] = useState<MyInss[]>([]);
  const [myInss, setMyInss] = useState<MyInss>();

  const [show, setShow] = useState<boolean>(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };

  useEffect(() => {
    api.get('/myinss-list').then((response) => {
      const myinssFormatted = response.data.map((item: MyInss) => {
        const d = new Date(`${item.der}T00:00:00`);
        return {
          ...item,
          creationDate: formatDate(item.created_at),
          formattedDer: isValid(d)
            ? format(d, " dd'/'MM'/'yyyy", {
                locale: ptBR,
              })
            : '',
          expiredDer: isBefore(parseISO(item.der), new Date()),
        };
      });

      console.log(myinssFormatted);

      setMyInssList(myinssFormatted);
    });
  }, []);

  const handleLoadDeadline = useCallback(
    (item: MyInss) => {
      formRef.current?.setData({ der: item.der });
      setMyInss(item);
      setShow(!show);
    },
    [show],
  );

  const handleSaveDeadline = useCallback(
    async (data: FormData) => {
      console.log('formData', data);
      await api.put('/myinss-list', {
        id: myInss?.id,
        client_id: myInss?.client.id,
        client_process_id: myInss?.client_process.id,
        der: data.der,
        done: false,
      });
      api.get('/myinss-list').then((response) => {
        const myinssFormatted = response.data.map((item: MyInss) => {
          const d = new Date(`${item.der}T00:00:00`);
          return {
            ...item,
            creationDate: formatDate(item.created_at),
            formattedDer: isValid(d)
              ? format(d, " dd'/'MM'/'yyyy", {
                  locale: ptBR,
                })
              : '',
            expiredDer: isBefore(parseISO(item.der), new Date()),
          };
        });

        setMyInssList(myinssFormatted);
        setShow(false);

        addToast({
          type: 'success',
          title: 'Prazo atualizado',
          description: 'Prazo atualizado com sucesso',
        });
      });
    },
    [addToast, myInss],
  );

  const handleDeleteListItem = useCallback(async (listId: string) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      await api.delete(`/myinss-list/${listId}`).then((response) => {
        setMyInssList((item) => item.filter((_item) => _item.id !== listId));
      });
    }
  }, []);

  const handleCallNewMyinss = useCallback(() => {
    history.push('/new-myinss-list');
  }, [history]);

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Meu INSS</h1>

          <FormContainer>
            <Button type="button" onClick={handleCallNewMyinss}>
              Novo
            </Button>
          </FormContainer>

          <Form ref={formRef} onSubmit={handleSaveDeadline}>
            <DerContainer>
              <div className={show ? 'showDiv' : 'hideDiv'}>
                <InputWithLabel
                  name="der"
                  placeholder=""
                  label="DER"
                  type="date"
                />
                <Button type="submit" title="Salvar">
                  Salvar
                </Button>
              </div>
            </DerContainer>
          </Form>

          <Section>
            <MyInssList key="aswqw">
              <div>
                <strong>
                  <b>Cliente</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>CPF</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>Senha</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>Histórico</b>
                </strong>
              </div>
              <div>
                <strong>
                  <b>DER</b>
                </strong>
              </div>
              <div>
                <strong>&nbsp;</strong>
              </div>
            </MyInssList>
            {myInssList.map((item) => (
              <MyInssList key={item.id}>
                <div>
                  <strong>{item.client.name}</strong>
                </div>

                <div>
                  <strong>{item.client.cpf}</strong>
                </div>

                <div>
                  <strong>{item.client.password}</strong>
                </div>

                <div>
                  <strong>{item.process_attendance.description}</strong>
                </div>

                <div>
                  <strong className={item.expiredDer ? 'expiredDeadline' : ''}>
                    {item.der ? item.formattedDer : 'Sem data'}
                  </strong>
                </div>

                <div>
                  <FiEdit3
                    title="Editar DER"
                    onClick={() => {
                      item && handleLoadDeadline(item);
                    }}
                  />

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      item.id && handleDeleteListItem(item.id);
                    }}
                  />

                  <Link
                    to={{
                      pathname: '/finish-myinss-list-item',
                      state: item,
                    }}
                    title="Concluir"
                  >
                    <FiCheckSquare />
                  </Link>
                </div>
              </MyInssList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default MyInss;
