/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer } from './styles';

import api from '../../../../services/api';

import LeftMenu from '../../../../components/LeftMenu';
import InputWithLabel from '../../../../components/InputWithLabel';
import TextArea from '../../../../components/TextArea';
import Button from '../../../../components/Button';

interface MyInssFormData {
  attendance_date: string;
  folder: string;
  description: string;
}

interface Location {
  id: string;
  name: string;
}

interface Client {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
}

const NewMyinssList: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [clientProcess, setClientProcess] = useState<ClientProcess>();

  const locationState: Location = location.state as Location;

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (locationState) {
      formRef.current?.setData({ name: locationState.name });
    }
  }, [locationState]);

  const handleSubmit = useCallback(
    async (data: MyInssFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          folder: Yup.string().required('Pasta obrigatória'),
          attendance_date: Yup.string().required(
            'Data do andamento obrigatória',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const attendanceType = await api.get('/attendance-types/name/Meu INSS');

        console.log(attendanceType);
        console.log(clientProcess);

        if (clientProcess) {
          const processAttendance = await api.post('/process-attendance', {
            client_id: clientProcess?.client.id,
            attendance_type_id: attendanceType.data.id,
            client_process_id: clientProcess?.id,
            description: data.description,
            attendance_date: data.attendance_date,
          });

          await api.post('/myinss-list', {
            client_id: clientProcess?.client.id,
            client_process_id: clientProcess?.id,
            process_attendance_id: processAttendance.data.id,
            der: data.attendance_date,
            done: false,
          });

          history.push('/myinss-list');

          addToast({
            type: 'success',
            title: 'Cadastro realizado!',
            description: 'Meu INSS criado',
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso carregar o processo do cliente',
            description: 'Digite a pasta e clique na lupa.',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Ocorreu um erro ao cadastrar meu INSS.',
        });
      }
    },
    [addToast, clientProcess, history],
  );

  const handleFindByFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    if (folder) {
      api.get(`/client-process/folder/${folder}`).then((response) => {
        if (response.data) {
          setClientProcess(response.data);
        } else {
          setClientProcess(undefined);
          addToast({
            type: 'info',
            title: 'Pasta não encontrada',
            description: 'Não foi possível localizar a pasta informada.',
          });
        }
      });
    }
  }, [addToast]);

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Cadastro de Meu INSS</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <InputWithLabel name="folder" label="Pasta" type="text" />
              <Button
                type="button"
                title="Dados do cliente"
                onClick={handleFindByFolder}
              >
                <FiSearch size={30} />
              </Button>
              <label className="process-client">
                {clientProcess && clientProcess?.client.name}{' '}
                {clientProcess && ' - Processo: '}
                {clientProcess && clientProcess.process_number}
              </label>
            </FormContainer>

            <FormContainer>
              <InputWithLabel name="attendance_date" label="Data" type="date" />
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Histórico"
                rows={3}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Salvar</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default NewMyinssList;
