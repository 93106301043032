/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { FiXCircle, FiEdit3 } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  ContentArea,
  FormContainerHeader,
  FormContainer,
  Section,
  ProcessClient,
} from './styles';

import api from '../../services/api';

import ConfigurationMenu from '../../components/ConfigurationMenu';
import InputWithLabel from '../../components/InputWithLabel';
import Select from '../../components/Select';
import Button from '../../components/Button';

interface RestrictionFormData {
  user: string;
  week_day: string;
  start: string;
  finish: string;
}

interface User {
  id: string;
  name: string;
  email: string;
}

interface Restriction {
  id: string;
  week_day: string;
  user: User;
  start: string;
  finish: string;
}

interface WeekDay {
  id: string;
  name: string;
}

const UserRestriction: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const week_days: WeekDay[] = [
    {
      id: 'Domingo',
      name: 'Domingo',
    },
    {
      id: 'Segunda-feira',
      name: 'Segunda-feira',
    },
    {
      id: 'Terça-feira',
      name: 'Terça-feira',
    },
    {
      id: 'Quarta-feira',
      name: 'Quarta-feira',
    },
    {
      id: 'Quinta-feira',
      name: 'Quinta-feira',
    },
    {
      id: 'Sexta-feira',
      name: 'Sexta-feira',
    },
    {
      id: 'Sábado',
      name: 'Sábado',
    },
  ];

  const [users, setUsers] = useState<User[]>([]);
  const [restrictions, setRestrictions] = useState<Restriction[]>([]);
  const [selectedRestriction, setSelectedRestriction] = useState<Restriction>();

  useEffect(() => {
    api.get('/users').then((response) => {
      setUsers(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('user-restrictions').then((response) => {
      setRestrictions(response.data);
    });
  }, []);

  const handleAddProcess = useCallback(
    async (data: RestrictionFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          user: Yup.string().required('Usuário obrigatório'),
          week_day: Yup.string().required('Dia da Semana obrigatório'),
          start: Yup.string().required('Início obrigatório'),
          finish: Yup.string().required('Término obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!selectedRestriction) {
          await api.post('/user-restrictions', {
            user_id: data.user,
            week_day: data.week_day,
            start: data.start,
            finish: data.finish,
          });
        } else {
          await api.put('/user-restrictions', {
            id: selectedRestriction.id,
            user_id: data.user,
            week_day: data.week_day,
            start: data.start,
            finish: data.finish,
          });
        }

        await api.get('user-restrictions').then((response) => {
          setRestrictions(response.data);
        });

        setSelectedRestriction(undefined);

        formRef.current?.reset();
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [selectedRestriction],
  );

  const handleDeleteProcess = useCallback(async (restrictionId: string) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      await api
        .delete(`/user-restrictions/${restrictionId}`)
        .then((response) => {
          setRestrictions((restr) =>
            restr.filter((_restr) => _restr.id !== restrictionId),
          );
        });
    }
  }, []);

  const handleLoadValuesToFields = useCallback((restriction: Restriction) => {
    // formRef.current?.reset();

    setSelectedRestriction(restriction);

    formRef.current?.setData({ user: restriction.user.id });
    formRef.current?.setData({ week_day: restriction.week_day });
    formRef.current?.setData({ start: restriction.start });
    formRef.current?.setData({ finish: restriction.finish });
  }, []);

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h2>Restrição de Uso</h2>

          <Form ref={formRef} onSubmit={handleAddProcess}>
            <FormContainer>
              <Select
                name="user"
                label="Usuário"
                initialLabel="Selecione..."
                options={users.map((user) => ({
                  value: user.id,
                  label: user.name,
                }))}
              />

              <Select
                name="week_day"
                label="Dia da Semana"
                initialLabel="Selecione..."
                options={week_days.map((week_day) => ({
                  value: week_day.id,
                  label: week_day.name,
                }))}
              />

              <InputWithLabel name="start" label="Início" type="time" />
              <InputWithLabel name="finish" label="Término" type="time" />

              <Button type="submit">Salvar</Button>
            </FormContainer>

            <FormContainerHeader>
              <strong>Usuário</strong>
              <strong>Dia da Semana</strong>
              <strong>Início</strong>
              <strong>Término</strong>
            </FormContainerHeader>

            <Section>
              {restrictions.map((restriction) => (
                <ProcessClient key={restriction.id}>
                  <div>
                    <strong>{restriction.user.name}</strong>
                  </div>

                  <div>
                    <strong>{restriction.week_day}</strong>
                  </div>

                  <div>
                    <strong>{restriction.start}</strong>
                  </div>

                  <div>
                    &nbsp;&nbsp;
                    <strong>{restriction.finish}</strong>
                    <FiEdit3
                      title="Editar"
                      onClick={() => {
                        restriction.id && handleLoadValuesToFields(restriction);
                      }}
                    />
                    <FiXCircle
                      onClick={() => {
                        restriction.id && handleDeleteProcess(restriction.id);
                      }}
                      title="Excluir"
                    />
                  </div>
                </ProcessClient>
              ))}
            </Section>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default UserRestriction;
