/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-empty-pattern */
import React, {
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container, Error } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?: string;
  containerStyle?: object;
  icon?: React.ComponentType<IconBaseProps>;
  initialLabel?: string;
  options: Array<{
    value: string;
    label: string;
  }>;
}

const Select: React.FC<SelectProps> = ({
  name,
  label,
  containerStyle = {},
  icon: Icon,
  options,
  initialLabel,
  ...rest
}) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [selectValue, setSelectValue] = useState<string | undefined>('');

  const { fieldName, error, registerField } = useField(name);

  const handleChange = useCallback(() => {
    setSelectValue(inputRef.current?.value);
    // ref.select.select.setValue(value);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    // Se o inputRef tiver valor então true, senão, false
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      setValue(ref: HTMLSelectElement, value: string) {
        if (value) {
          setSelectValue(value);
        }
      },
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <div>
      <label htmlFor={name}>{label}</label>
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        {Icon && <Icon size={20} />}

        <select
          value={selectValue}
          id={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleChange}
          ref={inputRef}
          {...rest}
        >
          <option value="" disabled hidden>
            {initialLabel}
          </option>
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>

        {error && (
          <Error title={error}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </div>
  );
};

export default Select;
