import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/Signin';
import SignUp from '../pages/Signup';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import NewClient from '../pages/NewClient';
import UpdateClient from '../pages/UpdateClient';
import Process from '../pages/Process';
import ProcessAttendance from '../pages/ProcessAttendance';
import ProcessType from '../pages/ProcessType';
import ProcessTypeForm from '../pages/ProcessType/ProcessTypeForm';
import Competence from '../pages/Competence';
import CompetenceForm from '../pages/Competence/CompetenceForm';
import AttendanceType from '../pages/AttendanceType';
import AttendanceTypeForm from '../pages/AttendanceType/AttendanceTypeForm';
import ClientList from '../pages/ClientList';
// import Configuration from '../pages/Configuration';
import List from '../pages/List';
import JudicialImplantationList from '../pages/List/JudicialImplantationList';
import ContactClientList from '../pages/List/ContactClientList';
import NewList from '../pages/List/JudicialImplantationList/NewList';
import FinishJudicialImplantationListItem from '../pages/List/JudicialImplantationList/FinishJudicialImplantationListItem';
import NewContactClientList from '../pages/List/ContactClientList/NewContactClientClient';
import FinishContactClientListItem from '../pages/List/ContactClientList/FinishContactClientListItem';
import MyInss from '../pages/List/MyInss';
import NewMyinssList from '../pages/List/MyInss/NewMyInssList';
import FinishMyinss from '../pages/List/MyInss/FinishMyinss';

import AdminImplantationList from '../pages/List/AdminImplantationList';
import NewAdminList from '../pages/List/AdminImplantationList/NewAdminList';
import FinishAdminImplantationListItem from '../pages/List/AdminImplantationList/FinishAdminImplantationListItem';

import DocumentReceipt from '../pages/Receipt/Document';
import DocumentReceiptPdf from '../pages/Receipt/DocumentReceiptPdf';
import DocumentReceiptNoFolder from '../pages/Receipt/DocumentNoFolder';
import DocumentReceiptPdfNoFolder from '../pages/Receipt/DocumentReceiptPdfNoFolder';
import ValueReceiptNoFolder from '../pages/Receipt/ValueNoFolder';
import ValueReceiptPdfNoFolder from '../pages/Receipt/ValueReceiptPdfNoFolder';

import ValueReceipt from '../pages/Receipt/Value';
import ValueReceiptPdf from '../pages/Receipt/ValueReceiptPdf';

import UserRestriction from '../pages/UserRestriction';

import UserList from '../pages/User';
import UserForm from '../pages/User/UserForm';
import ManageUserList from '../pages/ManageUserList';

import InactivateClient from '../pages/InactivateClient';
import InactivateProcess from '../pages/InactivateProcess';
import Intimation from '../pages/Intimation';
import NewIntimation from '../pages/Intimation/NewIntimation';
import UpdateIntimation from '../pages/Intimation/UpdateIntimation';
import WordDocument from '../pages/WordDocument';
import BackupDatabase from '../pages/BackupDatabase';
import FinishIntimation from '../pages/Intimation/FinishIntimationListItem';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" component={SignUp} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/new-client" component={NewClient} isPrivate />
    <Route path="/update-client" component={UpdateClient} isPrivate />
    <Route path="/client-process" component={Process} isPrivate />
    <Route path="/process-attendance" component={ProcessAttendance} isPrivate />
    <Route path="/manage-users" component={ManageUserList} isPrivate />

    <Route path="/process-type" component={ProcessType} isPrivate />
    <Route path="/process-type-form" component={ProcessTypeForm} isPrivate />
    <Route path="/competence" component={Competence} isPrivate />
    <Route path="/competence-form" component={CompetenceForm} isPrivate />
    <Route path="/attendance-type" component={AttendanceType} isPrivate />
    <Route path="/clients-list" component={ClientList} isPrivate />
    <Route path="/inactivate-client" component={InactivateClient} isPrivate />
    <Route path="/inactivate-process" component={InactivateProcess} isPrivate />
    <Route
      path="/attendance-type-form"
      component={AttendanceTypeForm}
      isPrivate
    />

    <Route path="/configuration" component={ProcessType} isPrivate />
    <Route path="/lists" component={List} isPrivate />
    <Route
      path="/judicial-implantation-list"
      component={JudicialImplantationList}
      isPrivate
    />
    <Route
      path="/contact-client-list"
      component={ContactClientList}
      isPrivate
    />

    <Route
      path="/new-judicial-implantation-list"
      component={NewList}
      isPrivate
    />
    <Route
      path="/finish-judicial-implantation-list-item"
      component={FinishJudicialImplantationListItem}
      isPrivate
    />

    <Route
      path="/new-contact-client-list"
      component={NewContactClientList}
      isPrivate
    />
    <Route
      path="/finish-contact-client-list-item"
      component={FinishContactClientListItem}
      isPrivate
    />

    <Route path="/myinss-list" component={MyInss} isPrivate />
    <Route path="/new-myinss-list" component={NewMyinssList} isPrivate />
    <Route path="/finish-myinss-list-item" component={FinishMyinss} isPrivate />

    <Route
      path="/admin-implantation-list"
      component={AdminImplantationList}
      isPrivate
    />
    <Route
      path="/new-admin-implantation-list"
      component={NewAdminList}
      isPrivate
    />
    <Route
      path="/finish-admin-implantation-list-item"
      component={FinishAdminImplantationListItem}
      isPrivate
    />

    <Route path="/document-receipt" component={DocumentReceipt} isPrivate />
    <Route
      path="/document-receipt-pdf"
      component={DocumentReceiptPdf}
      isPrivate
    />

    <Route
      path="/document-receipt-no-folder"
      component={DocumentReceiptNoFolder}
      isPrivate
    />
    <Route
      path="/document-receipt-pdf-no-folder"
      component={DocumentReceiptPdfNoFolder}
      isPrivate
    />

    <Route path="/value-receipt" component={ValueReceipt} isPrivate />
    <Route path="/value-receipt-pdf" component={ValueReceiptPdf} isPrivate />
    <Route
      path="/value-receipt-no-folder"
      component={ValueReceiptNoFolder}
      isPrivate
    />
    <Route
      path="/value-receipt-pdf-no-folder"
      component={ValueReceiptPdfNoFolder}
      isPrivate
    />

    <Route path="/user-restriction" component={UserRestriction} isPrivate />
    <Route path="/user" component={UserList} isPrivate />
    <Route path="/user-form" component={UserForm} isPrivate />
    <Route path="/intimation" component={Intimation} isPrivate />
    <Route path="/new-intimation" component={NewIntimation} isPrivate />
    <Route path="/update-intimation" component={UpdateIntimation} isPrivate />
    <Route path="/word-document" component={WordDocument} isPrivate />
    <Route path="/backup-database" component={BackupDatabase} isPrivate />
    <Route
      path="/finish-intimation-list-item"
      component={FinishIntimation}
      isPrivate
    />
  </Switch>
);

export default Routes;
