import styled from 'styled-components';

export const Container = styled.div`
  /* margin-right: 50px; */
`;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const Content = styled.main`
  display: flex;
  /* flex: 1; */
  margin-right: 50px;
`;

export const ContentArea = styled.main`
  /* flex: 1; */

  height: 100vh;

  margin-left: 250px;
  /* margin-right: 50px; */

  h1 {
    margin-top: 40px;
    margin-left: 35px;
    color: #09432f;
  }

  h2 {
    margin-top: 50px;
    /* margin-left: 35px; */
    color: #09432f;
  }
`;

export const Section = styled.section`
  margin-top: 0;
  margin-right: 50px;
  margin-left: -45px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }

  button {
    width: 900px;
  }

  a {
    text-decoration: none;
  }

  h2 {
    margin-left: 35px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 110px;
  /* margin-left: -33px; */

  .MuiAutocomplete-popupIndicator {
    margin-top: -30px;

    /* .MuiIconButton-label {
      margin-left: 40px;
      svg {
        margin-left: 40px;
      }
    } */
  }

  & + div {
    margin-top: 16px;
  }

  div {
    /* flex: 1; */
    display: flex;
    align-items: center;
    padding: 16px 16px;
    border-radius: 10px;

    button {
      margin: 0;
      width: 60px;
      text-align: center;
    }
  }
`;

export const Client = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: 16px;
  }

  span {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #f4ede8;
    width: 70px;

    svg {
      color: #ff9000;
      margin-right: 8px;
    }
  }

  div {
    flex: 1;
    background: #333a56;
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-radius: 10px;
    margin-left: 24px;

    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }

    strong {
      margin-left: 24px;
      color: #fff;
      font-size: 20px;
    }
  }
`;

export const GridContainer = styled.div`
  margin-top: 0;
  justify-content: center;
  align-items: center;

  display: grid;
  /* grid-template-columns: 3fr 0.5fr 1fr 1fr; */
  grid-template-columns: 2fr 0.5fr 0.5fr 1fr;

  /* grid-template-columns: 380px 200px 250px 200px; */

  column-gap: 0.5rem;
  row-gap: 1.6rem;

  .MuiAutocomplete-popupIndicator {
    margin-top: -30px;

    /* .MuiIconButton-label {
      margin-left: 40px;
      svg {
        margin-left: 40px;
      }
    } */
  }

  & + div {
    margin-top: 16px;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    border-radius: 10px;

    button {
      margin: 0;
      width: 60px;
      text-align: center;
      margin-right: 8px;
    }
  }
`;

export const BigButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    flex-grow: 1;
    margin-left: 35px;
    button {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const ListsContainer = styled.div`
  margin-top: 30px;
  margin-left: 35px;
  justify-content: start;
  align-items: center;

  display: grid;
  /* grid-template-columns: 215px 215px 215px 215px; */
  grid-template-columns: 1fr 1fr 1fr 1fr;

  row-gap: 2.6rem;
  column-gap: 1.6rem;

  & + div {
    margin-top: 16px;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 16px;
    border-radius: 10px;
    height: 100px;
    /* width: 200px; */
  }

  .judicial-implantation {
    color: #fff;
    background-color: #09432f;
  }

  .notification-label {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #fff;
    height: 35px;
    padding: 4px 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -5px;
  }

  .contact-client {
    color: #fff;
    background-color: #00b35b;
  }

  .myinss {
    color: #fff;
    background-color: #09432f;
  }

  .admin-implantation {
    color: #fff;
    background-color: #00b35b;
  }
`;
