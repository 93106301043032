import React, { useState, useEffect, useCallback } from 'react';

import { FiEdit3, FiXCircle } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  ContentArea,
  Section,
  ClientItemList,
} from './styles';

import api from '../../services/api';

import ConfigurationMenu from '../../components/ConfigurationMenu';

interface Client {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
}

const ClientList: React.FC = () => {
  const history = useHistory();
  const [clients, setClients] = useState<Client[]>([]);

  useEffect(() => {
    api.get('/clients').then((response) => {
      setClients(response.data);
    });
  }, []);

  const handleDeleteClient = useCallback(
    async (client: Client) => {
      history.push('/inactivate-client', { client });
      /*
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      await api.delete(`/clients/${clientId}`).then((response) => {
        setClients((cli) => cli.filter((_cli) => _cli.id !== clientId));
      });
    }
    */
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Listagem de Clientes</h1>

          <Section>
            {clients.map((selectedClient) => (
              <ClientItemList key={selectedClient.id}>
                <div>
                  <strong>{selectedClient.name}</strong>
                </div>

                <div>
                  <Link
                    to={{
                      pathname: '/update-client',
                      state: { selectedClient },
                    }}
                    title="Editar"
                  >
                    <FiEdit3 />
                  </Link>

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      selectedClient.id && handleDeleteClient(selectedClient);
                    }}
                  />
                </div>
              </ClientItemList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ClientList;
