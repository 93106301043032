import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div``;

export const Content = styled.main`
  /* max-width: 1120px; */
  margin-right: 80px;
  display: flex;
`;

export const ContentArea = styled.main`
  /* position: absolute; */
  height: 100vh;

  margin-left: 250px;

  h1 {
    margin-top: 40px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 0;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  a {
    text-decoration: none;
    color: #323a55;
  }

  label {
    cursor: pointer;
  }

  .birthDiv {
    margin-top: 20px;
  }

  /* input {
    text-transform: uppercase;
  } */
`;

export const FormModalContainer = styled.div`
  margin-top: 0;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 2.5fr 1.5fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  a {
    text-decoration: none;
    color: #323a55;
  }

  label {
    cursor: pointer;
  }

  .MuiAutocomplete-popupIndicator {
    margin-top: -10px;
  }

  .button-add {
    // margin: 0;
    background: #09432f;
    height: 46px;
    border-radius: 10px;
    border: 0;
    padding: 0 16px;
    color: #fff;
    width: 30%;
    font-weight: 500;
    margin-top: 16px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#09432f')};
    }
  }

  

  /* input {
    text-transform: uppercase;
  } */
`;


export const FormModalTextAreaContainer = styled.div`
  margin-top: 0;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 4fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  a {
    text-decoration: none;
    color: #323a55;
  }

  label {
    cursor: pointer;
  }

`;

export const BackContainer = styled.div`
  margin-top: 0;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  a {
    text-decoration: none;
    color: #323a55;
  }

  label {
    cursor: pointer;
  }

  .hideDiv {
    display: none;
  }

  .showDiv {
    display: block;
  }
`;

export const ObservationContainer = styled.div`
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 4fr;
`;

export const Section = styled.section`
  margin-top: 10px;

  > strong {
    color: #09432f;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #09432f;
  }
`;

export const Association = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 1px solid #2d344c;

  /* & + div {
    margin-top: 5px;
  } */

  span {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #f4ede8;
    width: 70px;
  }

  div {
    flex: 1;
    // background: #e8e8e8;
    display: flex;
    align-items: center;
    padding: 16px 8px;
    /* border-radius: 10px; */
    /* margin-left: 8px; */

    strong {
      margin-left: 3px;
      color: #323a55;
      font-size: 16px;
    }

    svg {
      color: #ff9000;
      margin-left: 24px;
      cursor: pointer;
    }
  }
`;