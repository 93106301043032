/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { isBefore, parseISO, format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { FiEdit3, FiXCircle, FiCheckSquare, FiInfo } from 'react-icons/fi';

import {
  Container,
  Content,
  ContentArea,
  DeadlineContainer,
  FormContainer,
  Avatar,
  StyledTooltip,
  FlexHeaderTable,
  TableColumn,
  FlexRow,
  useStyles,
  TableColumnProcesso,
} from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import LeftMenu from '../../../components/LeftMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import Button from '../../../components/Button';
import TextArea from '../../../components/TextArea';

interface Client {
  id: string;
  name: string;
  cpf: string;
  password: string;
}

interface City {
  id: string;
  name: string;
  uf: string;
}

interface ClientComplete {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  city: City;
}

interface ClientProcess {
  id: string;
  process_number: string;
}

interface ProcessAttendance {
  id: string;
  description: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
}

interface AdminImplantation {
  id: string;
  deadline: string;
  client: Client;
  client_process: ClientProcess;
  created_at: Date;
  creationDate: string;
  formattedDeadline: string;
  expiredDeadline: boolean;
  process_attendance: ProcessAttendance;
  description: string;
  user: User;
  avatar_url: string;
  updateUser: User;
  avatar_update_user: string;
}

interface FormData {
  deadline: Date;
  description: string;
}

const AdminImplantationList: React.FC = () => {
  const [adminImplantations, setAdminImplantations] = useState<
    AdminImplantation[]
  >([]);
  const [
    adminImplantation,
    setAdminImplantation,
  ] = useState<AdminImplantation>();

  const [show, setShow] = useState<boolean>(false);

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { user } = useAuth();
  const classes = useStyles();

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };

  useEffect(() => {
    api.get('/admin-implantation-list').then((response) => {
      const implantationsFormatted = response.data.map(
        (implantation: AdminImplantation) => {
          const d = new Date(`${implantation.deadline}T00:00:00`);
          return {
            ...implantation,
            creationDate: formatDate(implantation.created_at),
            formattedDeadline: isValid(d)
              ? format(d, " dd'/'MM'/'yyyy", {
                  locale: ptBR,
                })
              : '',
            expiredDeadline: isBefore(
              parseISO(implantation.deadline),
              new Date(),
            ),
            avatar_url: implantation.user ? `https://app-gobarberamx.s3.amazonaws.com/${implantation.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            avatar_update_user: implantation.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${implantation.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
          };
        },
      );

      setAdminImplantations(implantationsFormatted);
    });
  }, []);

  const handleLoadDeadline = useCallback((implantation: AdminImplantation) => {
    window.scrollTo(0, 0);
    formRef.current?.setData({ deadline: implantation.deadline });
    formRef.current?.setData({ description: implantation.description });
    setAdminImplantation(implantation);
    // setShow(!show);
    setShow(true);
  }, []);

  const handleSaveDeadline = useCallback(
    async (data: FormData) => {
      await api.put('/admin-implantation-list', {
        id: adminImplantation?.id,
        client_id: adminImplantation?.client.id,
        client_process_id: adminImplantation?.client_process.id,
        deadline: data.deadline,
        description: data.description,
        update_user_id: user.id,
      });
      // await api.put('/process-attendance', {
      //   id: adminImplantation?.process_attendance.id,
      //   client_id: adminImplantation?.client.id,
      //   description: data.description,
      // });
      api.get('/admin-implantation-list').then((response) => {
        const implantationsFormatted = response.data.map(
          (implantation: AdminImplantation) => {
            const d = new Date(`${implantation.deadline}T00:00:00`);
            return {
              ...implantation,
              creationDate: formatDate(implantation.created_at),
              formattedDeadline: isValid(d)
                ? format(d, " dd'/'MM'/'yyyy", {
                    locale: ptBR,
                  })
                : '',
              expiredDeadline: isBefore(
                parseISO(implantation.deadline),
                new Date(),
              ),
              avatar_url: implantation.user ? `https://app-gobarberamx.s3.amazonaws.com/${implantation.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
              avatar_update_user: implantation.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${implantation.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            };
          },
        );

        setAdminImplantations(implantationsFormatted);
        setShow(false);

        addToast({
          type: 'success',
          title: 'Prazo atualizado',
          description: 'Prazo atualizado com sucesso',
        });
      });
    },
    [addToast, adminImplantation, user.id],
  );

  const handleDeleteListItem = useCallback(async (listId: string, itemUser: User) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      if (itemUser) {
        if (user.id !== itemUser.id) {
          alert('Você não pode excluir um lançamento feito por outro usuário');
        }
        else {
          await api
          .delete(`/admin-implantation-list/${listId}`)
          .then((response) => {
            setAdminImplantations((item) =>
              item.filter((_item) => _item.id !== listId),
            );
          });
        }
      }
      else {
        await api
        .delete(`/admin-implantation-list/${listId}`)
        .then((response) => {
          setAdminImplantations((item) =>
            item.filter((_item) => _item.id !== listId),
          );
        });
      }
    }
  }, [user.id]);

  const handleCallNewAdminImplantation = useCallback(() => {
    history.push('/new-admin-implantation-list');
  }, [history]);

  const handleCopyToClipboard = useCallback((process_number: string) => {
    // eslint-disable-next-line no-alert
    alert(process_number);
    // navigator.clipboard.writeText(process_number);

    // addToast({
    //   type: 'info',
    //   title: 'Copiado',
    //   description: 'Número do processo copiado para a área de transferência',
    // });
  }, []);

  const handleCallClientView = useCallback(
    async (client: Client) => {
      await api.get(`/clients/cpf/${client.cpf}`).then((response) => {
        const cl: ClientComplete = response.data;

        localStorage.setItem(
          '@Rastreio:selectedClient',
          JSON.stringify(cl),
        );
        history.push('/update-client', { selectedClient: cl, caller: 'admin-implantation-list' });
      });
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Implantação Administrativa</h1>

          <FormContainer>
            <Button type="button" onClick={handleCallNewAdminImplantation}>
              Novo
            </Button>
          </FormContainer>

          <Form ref={formRef} onSubmit={handleSaveDeadline}>
            <DeadlineContainer>
              <div className={show ? 'showDiv' : 'hideDiv'}>
                <InputWithLabel
                  name="deadline"
                  placeholder=""
                  label="Prazo"
                  type="date"
                />
                <TextArea
                  name="description"
                  label="Histórico"
                  rows={2}
                  cols={400}
                  style={{fontSize: 18}}
                />
                <Button type="submit" title="Salvar">
                  Salvar
                </Button>
              </div>
            </DeadlineContainer>
          </Form>

          <FlexHeaderTable>
            <FlexRow>
              <TableColumn width="1" textAlign="center"><b>Usuário</b></TableColumn>
              <TableColumn width="3" textAlign="center"><b>Cliente</b></TableColumn>
              <TableColumn width="1.5" textAlign="center"><b>Processo</b></TableColumn>
              <TableColumn width="2" textAlign="center"><b>CPF</b></TableColumn>
              <TableColumn width="3" textAlign="center"><b>Histórico</b></TableColumn>
              <TableColumn width="1.5" textAlign="center"><b>Prazo</b></TableColumn>
              <TableColumn width="2.5" textAlign="center">&nbsp;</TableColumn>
            </FlexRow>
            {adminImplantations.map((item) => (
              <FlexRow>
                <TableColumn width="1">
                  <StyledTooltip title="Criador" arrow classes={{ tooltip: classes.customTooltip }}>
                    <Avatar>
                      {item.user ? (
                        <img src={item.avatar_url} alt={item.user.name} title={item.user.name} />
                      ) : (
                        <img
                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          alt="avatar"
                        />
                      )}
                    </Avatar>
                  </StyledTooltip>

                  <StyledTooltip title="Alterador" arrow classes={{ tooltip: classes.customTooltip }}>
                    <Avatar>
                      {item.updateUser ? (
                        <img src={item.avatar_update_user} alt={item.updateUser.name} title={item.updateUser.name} />
                      ) : (
                        <img
                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          alt="avatar"
                        />
                      )}
                    </Avatar>
                  </StyledTooltip>
                </TableColumn>
                <TableColumn width="3">
                    <Link
                      to="/admin-implantation-list"
                      title="Clique para dados do cliente"
                      onClick={() => {
                        item && handleCallClientView(item.client);
                      }}
                    >
                      <StyledTooltip title={item.client.name ? item.client.name : ''} arrow
                        classes={{ tooltip: classes.customTooltip }}
                      >
                        <strong>{item.client.name}</strong>
                      </StyledTooltip>
                    </Link>
                </TableColumn>
                <TableColumnProcesso width="1.5">
                    <Link
                      to="/admin-implantation-list"
                      title="Copiar"
                      onClick={() => {
                        item &&
                          handleCopyToClipboard(
                            item.client_process.process_number,
                          );
                      }}
                    >
                      <StyledTooltip title={item.client_process.process_number ? item.client_process.process_number : ''} arrow
                        classes={{ tooltip: classes.customTooltip }}
                      >
                        <strong>{item.client_process.process_number}</strong>
                      </StyledTooltip>
                    </Link>
                </TableColumnProcesso>
                <TableColumn width="2">
                    <strong>{item.client.cpf}</strong>
                </TableColumn>
                <TableColumn width="3">
                    <StyledTooltip title={item.description ? item.description : ''} arrow
                      classes={{ tooltip: classes.customTooltip }}
                    >
                      <strong>{item.description}</strong>
                    </StyledTooltip>
                </TableColumn>
                <TableColumn width="1.5">
                    <strong
                      className={item.expiredDeadline ? 'expiredDeadline' : ''}
                    >
                      {item.deadline ? item.formattedDeadline : 'Sem prazo'}
                    </strong>
                </TableColumn>
                <TableColumn width="2.5">
                  <FiInfo
                    title="Ver histórico"
                    onClick={() => {
                      alert(`${item.description}`);
                    }}
                  />

                  <FiEdit3
                    title="Editar prazo"
                    onClick={() => {
                      item && handleLoadDeadline(item);
                    }}
                  />

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      item.id && handleDeleteListItem(item.id, item.user);
                    }}
                  />

                  <Link
                    to={{
                      pathname: '/finish-admin-implantation-list-item',
                      state: item,
                    }}
                    title="Concluir"
                  >
                    <FiCheckSquare />
                  </Link>
                </TableColumn>
              </FlexRow>
            ))}  
          </FlexHeaderTable>

        </ContentArea>
      </Content>
    </Container>
  );
};

export default AdminImplantationList;
