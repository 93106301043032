import React, { useCallback, useState, useEffect } from 'react';

import { FiEdit3, FiXCircle } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  ContentArea,
  Section,
  FormContainer,
  AttendanceTypeItemList,
} from './styles';

import api from '../../services/api';

import ConfigurationMenu from '../../components/ConfigurationMenu';
import Button from '../../components/Button';

interface AttendanceType {
  id: string;
  name: string;
}

const AttendanceType: React.FC = () => {
  const history = useHistory();

  const [attendanceTypes, setAttendanceTypes] = useState<AttendanceType[]>([]);

  useEffect(() => {
    api.get('/attendance-types').then((response) => {
      setAttendanceTypes(response.data);
    });
  }, []);

  const handleCallNewAttendanceTypeView = useCallback(() => {
    history.push('/attendance-type-form');
  }, [history]);

  const handleDeleteAttendanceType = useCallback(
    async (attendanceTypeId: string) => {
      // eslint-disable-next-line no-restricted-globals
      const c = confirm('Deseja excluir?');
      if (c === true) {
        await api
          .delete(`/attendance-types/${attendanceTypeId}`)
          .then((response) => {
            setAttendanceTypes((atte) =>
              atte.filter((_atte) => _atte.id !== attendanceTypeId),
            );
          });
      }
    },
    [],
  );

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Tipos de Andamento</h1>
          <FormContainer>
            <Button type="button" onClick={handleCallNewAttendanceTypeView}>
              Novo
            </Button>
          </FormContainer>

          <Section>
            {attendanceTypes.map((attType) => (
              <AttendanceTypeItemList key={attType.id}>
                <div>
                  <strong>{attType.name}</strong>
                </div>

                <div>
                  <Link
                    to={{
                      pathname: '/attendance-type-form',
                      state: attType,
                    }}
                    title="Editar"
                  >
                    <FiEdit3 />
                  </Link>

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      attType.id && handleDeleteAttendanceType(attType.id);
                    }}
                  />
                </div>
              </AttendanceTypeItemList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default AttendanceType;
