import React, { useCallback, useState, useEffect } from 'react';

import { FiEdit3, FiXCircle } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  ContentArea,
  Section,
  FormContainer,
  CompetenceItemList,
} from './styles';

import api from '../../services/api';

import ConfigurationMenu from '../../components/ConfigurationMenu';
import Button from '../../components/Button';

interface Comp {
  id: string;
  name: string;
}

const Competence: React.FC = () => {
  const history = useHistory();

  const [competences, setCompetences] = useState<Comp[]>([]);

  useEffect(() => {
    api.get('/competences').then((response) => {
      setCompetences(response.data);
    });
  }, []);

  const handleCallNewCompetenceView = useCallback(() => {
    history.push('/competence-form');
  }, [history]);

  const handleDeleteCompetence = useCallback(async (competenceId: string) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      await api.delete(`/competences/${competenceId}`).then((response) => {
        setCompetences((comp) =>
          comp.filter((_comp) => _comp.id !== competenceId),
        );
      });
    }
  }, []);

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Competências</h1>
          <FormContainer>
            <Button type="button" onClick={handleCallNewCompetenceView}>
              Nova
            </Button>
          </FormContainer>

          <Section>
            {competences.map((competence) => (
              <CompetenceItemList key={competence.id}>
                <div>
                  <strong>{competence.name}</strong>
                </div>

                <div>
                  <Link
                    to={{
                      pathname: '/competence-form',
                      state: competence,
                    }}
                    title="Editar"
                  >
                    <FiEdit3 />
                  </Link>

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      competence.id && handleDeleteCompetence(competence.id);
                    }}
                  />
                </div>
              </CompetenceItemList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default Competence;
