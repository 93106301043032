/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, { useRef } from 'react';
// import * as Yup from 'yup';

import { useLocation, Link } from 'react-router-dom';

import { FiArrowLeft } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';


import {
  Container,
  Content,
  ContentArea,
  FormContainer,
} from './styles';

import LeftMenu from '../../components/LeftMenu';


interface City {
  id: string;
  name: string;
  uf: string;
}

interface Competence {
  id: string;
  name: string;
}

interface ProcessType {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
}

interface Client {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  city: City;
}

interface ClientProcess {
  id: string;
  client_id: string;
  folder: string;
  process_type_id: string;
  competence_id: string;
  process_number: string;
}

interface Location {
  client: Client;
  city: City;
}

const WordDocument: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  // const [displayType, setDisplayType] = useState<string>();

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };

  
  const location = useLocation();

  let selectedClient: Client = location.state as Client;

  if (!selectedClient.id) {
    const locationState: Location = location.state as Location;
    selectedClient = locationState.client;
  }

  

  const locationState: Location = location.state as Location;
  console.log(location.state);


  const geraDocumento = (e: any) => {
    var files = e.target.files;
    var reader = new FileReader();
    if (files.length === 0) {
      alert('No files selected');
    }
    reader.readAsBinaryString(files.item(0));

    reader.onerror = function (evt) {
      console.log('error reading file', evt);
      alert('error reading file' + evt);
    };
    reader.onload = function (evt) {
      if (evt.target != null) {
        const content = evt.target.result;
        if (content != null) {
          var zip = new PizZip(content);
          var doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });

          // Render the document (Replace {first_name} by John, {last_name} by Doe, ...)
          doc.render({
            NOMESEGURADO: `${locationState.client.name}`,
            RG: `${locationState.client.rg}`,
            CPF: `${locationState.client.cpf}`,
            DATANASCIMENTO: `${formatDate(locationState.client.birth_date)}`,
            ESTADOCIVIL: `${locationState.client.marital_status}`,
            PROFISSAO: `${locationState.client.profession}`,
            ENDERECO: `${locationState.client.address}`,
            CIDADE: `${locationState.city.name}`,
            ESTADO: `${locationState.city.uf}`,
            CEP: `${locationState.client.cep}`
          });

          var blob = doc.getZip().generate({
            type: 'blob',
            mimeType:
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            // compression: DEFLATE adds a compression step.
            // For a 50MB output document, expect 500ms additional CPU time
            compression: 'DEFLATE',
          });
          // Output the document using Data-URI
          saveAs(blob, 'output.docx');

        }
      }
      
    };
  };

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Gerar Documento</h1>

          <Form ref={formRef} onSubmit={() => console.log('lalala')}>
            <br />
            <FormContainer>
              <Link
                to={{
                  pathname: '/update-client',
                  state: { selectedClient },
                }}
              >
                <FiArrowLeft size={30} title="Cadastro do Cliente" />
              </Link>
            </FormContainer>
            
            <br />
            <input type="file" onChange={geraDocumento} />
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default WordDocument;
