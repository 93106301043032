import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const Content = styled.main`
  display: flex;
  flex: 1;
  width: 1000px;
`;

export const ContentArea = styled.main`
  flex: 1;

  height: 100vh;

  margin-left: 250px;
  margin-right: 50px;

  h1 {
    margin-top: 40px;
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`;

export const FormContainer = styled.div`
  margin-top: 30px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  .process-client {
    margin-left: -200px;
  }

  button {
    margin-top: 25px;
    margin-right: 0;
    width: 60px;
    text-align: center;
  }
`;

export const FormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-top: 16px;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    border-radius: 10px;

    button {
      margin: 0;
      width: 60px;
      text-align: center;
    }
  }
`;

// export const Container = styled.div``;

// export const Content = styled.main`
//   max-width: 1120px;
//   margin-right: 80px;
//   display: flex;
// `;

// export const ContentArea = styled.main`
//   height: 100vh;

//   margin-left: 250px;

//   h1 {
//     margin-top: 40px;
//   }
// `;
