import React, { useCallback } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { FiArrowLeft } from 'react-icons/fi';

import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useLocation, useHistory } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import { useAuth } from '../../../hooks/auth';
import UtilMenu from '../../../components/UtilMenu';

import { Container, Content, ContentArea } from './styles';

interface Client {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
}

interface ReceiptData {
  receiptData: {
    client: string;
    documents: string;
    clientProcess: ClientProcess;
    displayType: string;
  };
}

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
  },
  title: {
    marginTop: 60,
    alignItems: 'center',
  },
  section: {
    marginTop: 30,
    marginLeft: 30,
    padding: 10,
    // flexGrow: 1,
  },
  section2: {
    marginTop: 5,
    marginLeft: 30,
    padding: 10,
  },
  dateSection: {
    marginTop: 20,
    marginLeft: 30,
    padding: 10,
  },
  signature: {
    marginTop: 50,
    marginLeft: 30,
  },
});

const DocumentReceiptPdf: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { user } = useAuth();

  const locationState: ReceiptData = location.state as ReceiptData;
  console.log('state', locationState);

  const d = new Date();
  const formattedDate = format(d, " dd' de 'MMMM' de 'yyyy", {
    locale: ptBR,
  });

  const receiptDate = `Tubarão/SC,${formattedDate}.`;

  const handleGoback = useCallback(() => {
    history.push({
      pathname: '/document-receipt',
      state: {
        locationState,
      },
    });
  }, [history, locationState]);

  return (
    <Container>
      <Content>
        <UtilMenu />
        <ContentArea>
          <h1>Recibo de Documentos</h1>

          <Container>
            <FiArrowLeft
              style={{ marginTop: 15 }}
              size={30}
              title="Voltar"
              onClick={handleGoback}
              cursor="pointer"
            />
            <Content>
              <PDFViewer style={{ width: 2000, height: 1000 }}>
                <Document>
                  <Page size="A4" style={styles.page}>
                    <View style={styles.title}>
                      <Text
                        style={{
                          fontWeight: 900,
                          textDecoration: 'underline',
                          fontSize: 18,
                        }}
                      >
                        RECIBO
                      </Text>
                    </View>
                    <View style={styles.section}>
                      <Text style={{ fontSize: 14 }}>
                        Recebi de {user.name} {user.qualification}, nesta data,
                        os documentos abaixo:
                      </Text>
                    </View>
                    <View style={styles.section2}>
                      <Text
                        style={{
                          fontWeight: 'bold',
                          textDecoration: 'underline',
                          fontSize: 14,
                          lineHeight: 2,
                        }}
                      >
                        {locationState.receiptData.documents}
                      </Text>
                    </View>
                    <View style={styles.dateSection}>
                      <Text style={{ fontSize: 14 }}>{receiptDate}</Text>
                    </View>
                    <View style={styles.signature}>
                      <Text>_________________________</Text>
                      <Text
                        style={{
                          marginTop: 12,
                          fontSize: 16,
                          lineHeight: 2,
                        }}
                      >
                        {locationState.receiptData.client}
                      </Text>
                    </View>
                  </Page>
                </Document>
              </PDFViewer>
            </Content>
          </Container>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default DocumentReceiptPdf;
