/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useHistory, Link } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import { isBefore, parseISO, format, isValid } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { FiEdit3, FiXCircle, FiCheckSquare, FiInfo, } from 'react-icons/fi';

import {
  Container,
  Content,
  ContentArea,
  ContactDateContainer,
  FormContainer,
  Avatar,
  StyledTooltip,
  FlexHeaderTable,
  TableColumn,
  FlexRow,
  useStyles,
} from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

import LeftMenu from '../../../components/LeftMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import Button from '../../../components/Button';
import TextArea from '../../../components/TextArea';

interface Client {
  id: string;
  name: string;
  phones: string;
  cpf: string;
}

interface City {
  id: string;
  name: string;
  uf: string;
}

interface ClientComplete {
  id: string;
  name: string;
  cpf: string;
  birth_date: Date;
  nit: string;
  rg: string;
  password: string;
  phones: string;
  marital_status: string;
  email: string;
  address: string;
  city_id: string;
  cep: string;
  profession: string;
  city: City;
}

interface ProcessAttendance {
  id: string;
  description: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  folder: string;
  client: Client;
}

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
}

interface ContactClient {
  id: string;
  contact_date: string;
  client: Client;
  created_at: Date;
  creationDate: string;
  formattedContactDate: string;
  expiredContactDate: boolean;
  process_attendance: ProcessAttendance;
  description: string;
  clientProcess: ClientProcess;
  user: User;
  avatar_url: string;
  userResponsible: User;
  updateUser: User;
  avatar_update_user: string;
}

interface FormData {
  contact_date: Date;
  description: string;
}

const ContactClientList: React.FC = () => {
  const [contacts, setContacts] = useState<ContactClient[]>([]);
  const [contactClient, setContactClient] = useState<ContactClient>();

  const [show, setShow] = useState<boolean>(false);
  // const [selectedClient, setSelectedClient] = useState<ClientComplete>();

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const { user } = useAuth();
  const classes = useStyles();

  const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getUTCDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [day, month, year].join('/');
  };

  useEffect(() => {
    api.get('/contact-client-list').then((response) => {
      const contactClientFormatted = response.data.map(
        (contact: ContactClient) => {
          const d = new Date(`${contact.contact_date}T00:00:00`);
          return {
            ...contact,
            creationDate: formatDate(contact.created_at),
            formattedContactDate: isValid(d)
              ? format(d, " dd'/'MM'/'yyyy", {
                  locale: ptBR,
                })
              : '',
            expiredContactDate: isBefore(
              parseISO(contact.contact_date),
              new Date(),
            ),
            avatar_url: contact.user ? `https://app-gobarberamx.s3.amazonaws.com/${contact.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            avatar_update_user: contact.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${contact.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
          };
        },
      );

      console.log(contactClientFormatted);

      setContacts(contactClientFormatted);
    });
  }, []);

  const handleLoadContactDate = useCallback((contact: ContactClient) => {
    window.scrollTo(0, 0);
    formRef.current?.setData({ contact_date: contact.contact_date });
    formRef.current?.setData({ description: contact.description });
    setContactClient(contact);
    // setShow(!show);
    setShow(true);
  }, []);

  const handleSaveContactDate = useCallback(
    async (data: FormData) => {
      await api.put('/contact-client-list', {
        id: contactClient?.id,
        client_id: contactClient?.client.id,
        contact_date: data.contact_date,
        description: data.description,
        update_user_id: user.id,
      });
      // await api.put('/process-attendance', {
      //   id: contactClient?.process_attendance.id,
      //   client_id: contactClient?.client.id,
      //   description: data.description,
      // });
      
      api.get('/contact-client-list').then((response) => {
        const contactClientFormatted = response.data.map(
          (contact: ContactClient) => {
            const d = new Date(`${contact.contact_date}T00:00:00`);
            return {
              ...contact,
              creationDate: formatDate(contact.created_at),
              formattedContactDate: isValid(d)
                ? format(d, " dd'/'MM'/'yyyy", {
                    locale: ptBR,
                  })
                : '',
              expiredContactDate: isBefore(
                parseISO(contact.contact_date),
                new Date(),
              ),
              avatar_url: contact.user ? `https://app-gobarberamx.s3.amazonaws.com/${contact.user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
              avatar_update_user: contact.updateUser ? `https://app-gobarberamx.s3.amazonaws.com/${contact.updateUser.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
            };
          },
        );

        setContacts(contactClientFormatted);
        setShow(false);

        addToast({
          type: 'success',
          title: 'Data de contato atualizada',
          description: 'Data de contato atualizada com sucesso',
        });
      });
    },
    [addToast, contactClient, user.id,],
  );

  const handleDeleteListItem = useCallback(async (listId: string, itemUser: User) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      if (itemUser) {
        if (user.id !== itemUser.id) {
          alert('Você não pode excluir um lançamento feito por outro usuário');
        }
        else {
          await api.delete(`/contact-client-list/${listId}`).then((response) => {
            setContacts((item) => item.filter((_item) => _item.id !== listId));
          });
        }
      }
      else {
        await api.delete(`/contact-client-list/${listId}`).then((response) => {
          setContacts((item) => item.filter((_item) => _item.id !== listId));
        });
      }
    }
  }, [user.id]);

  const handleCallNewContactClient = useCallback(() => {
    history.push('/new-contact-client-list');
  }, [history]);

  const handleCallClientView = useCallback(
    async (client: Client) => {
      await api.get(`/clients/cpf/${client.cpf}`).then((response) => {
        const cl: ClientComplete = response.data;

        localStorage.setItem(
          '@Rastreio:selectedClient',
          JSON.stringify(cl),
        );
        history.push('/update-client', { selectedClient: cl, caller: 'contact-client-list' });
      });
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Contactar Clientes</h1>

          <FormContainer>
            <Button type="button" onClick={handleCallNewContactClient}>
              Novo
            </Button>
          </FormContainer>

          <Form ref={formRef} onSubmit={handleSaveContactDate}>
            <ContactDateContainer>
              <div className={show ? 'showDiv' : 'hideDiv'}>
                <InputWithLabel
                  name="contact_date"
                  placeholder=""
                  label="Data de contato"
                  type="date"
                />
                <TextArea
                  name="description"
                  label="Histórico"
                  rows={6}
                  cols={400}
                  style={{fontSize: 18}}
                />
                <Button type="submit" title="Salvar">
                  Salvar
                </Button>
              </div>
            </ContactDateContainer>
          </Form>

          <FlexHeaderTable>
            <FlexRow>
              <TableColumn width="1" textAlign="center"><b>Usuário</b></TableColumn>
              <TableColumn width="3" textAlign="center"><b>Cliente</b></TableColumn>
              <TableColumn width="1.5" textAlign="center"><b>Contato</b></TableColumn>
              <TableColumn width="3" textAlign="center"><b>Histórico</b></TableColumn>
              <TableColumn width="1.5" textAlign="center"><b>Ligar em</b></TableColumn>
              <TableColumn width="2" textAlign="center"><b>Responsável</b></TableColumn>
              <TableColumn width="2.5" textAlign="center">&nbsp;</TableColumn>
            </FlexRow>
            {contacts.map((item) => (
              <FlexRow>
                <TableColumn width="1">
                  <StyledTooltip title="Criador" arrow classes={{ tooltip: classes.customTooltip }}>
                    <Avatar>
                      {item.user ? (
                        <img src={item.avatar_url} alt={item.user.name} title={item.user.name} />
                      ) : (
                        <img
                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          alt="avatar"
                        />
                      )}
                    </Avatar>
                  </StyledTooltip>

                  <StyledTooltip title="Alterador" arrow classes={{ tooltip: classes.customTooltip }}>
                    <Avatar>
                      {item.updateUser ? (
                        <img src={item.avatar_update_user} alt={item.updateUser.name} title={item.updateUser.name} />
                      ) : (
                        <img
                          src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                          alt="avatar"
                        />
                      )}
                    </Avatar>
                  </StyledTooltip>
                </TableColumn>
                <TableColumn width="3">
                  <div>
                    <Link
                      to="/contact-client-list"
                      title="Clique para dados do cliente"
                      onClick={() => {
                        item && handleCallClientView(item.client);
                      }}
                    >
                      <StyledTooltip title={item.client.name ? item.client.name : ''} arrow
                        classes={{ tooltip: classes.customTooltip }}
                      >
                        <strong>{item.client.name}</strong>
                      </StyledTooltip>
                      
                    </Link>
                  </div>
                </TableColumn>
                <TableColumn width="1.5">
                  <StyledTooltip title={item.client.phones ? item.client.phones : ''} arrow
                          classes={{ tooltip: classes.customTooltip }}
                        >
                    <strong>{item.client.phones}</strong>
                  </StyledTooltip>
                </TableColumn>
                <TableColumn width="3">
                  <div>
                    <StyledTooltip title={item.description ? item.description : ''} arrow
                      classes={{ tooltip: classes.customTooltip }}
                    >
                      <strong>{item.description}</strong>
                    </StyledTooltip>
                  </div>
                </TableColumn>
                <TableColumn width="1.5">
                  <div>
                    <strong
                      className={
                        item.expiredContactDate ? 'expiredContactDate' : ''
                      }
                    >
                      {item.contact_date
                        ? item.formattedContactDate
                        : 'Sem data para contato'}
                    </strong>
                  </div>
                </TableColumn>
                <TableColumn width="2">
                  <StyledTooltip title={item.userResponsible ? item.userResponsible.name : ''} arrow
                      classes={{ tooltip: classes.customTooltip }}
                  >
                    <strong>{item.userResponsible ? item.userResponsible.name : ''}</strong>
                  </StyledTooltip>
                </TableColumn>
                <TableColumn width="2.5">
                  <FiInfo
                        title="Ver histórico"
                        onClick={() => {
                          alert(`${item.description}`);
                        }}
                    />

                  <FiEdit3
                    title="Editar prazo"
                    onClick={() => {
                      item && handleLoadContactDate(item);
                    }}
                  />

                  <FiXCircle
                    title="Excluir"
                    onClick={() => {
                      item.id && handleDeleteListItem(item.id, item.user);
                    }}
                  />

                  <Link
                    to={{
                      pathname: '/finish-contact-client-list-item',
                      state: item,
                    }}
                    title="Concluir"
                  >
                    <FiCheckSquare />
                  </Link>
                </TableColumn>

              </FlexRow>
            ))}
          </FlexHeaderTable>

          
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ContactClientList;
