import styled from 'styled-components';
import hexagonoImg from '../../assets/002_3.png';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  nav {
    background: #00b35b;
    background-image: url(${hexagonoImg});
    background-repeat: repeat;
    background-size: 120px;
    height: 100vh;
    width: 230px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    outline: none;

    header {
      padding: 1.2em 0.5em;
      text-align: center;

      a {
        text-decoration: none;
      }

      img {
        width: 100px;
        border-radius: 50%;
        overflow: hidden;
        border: 4px solid #ffea92;
        box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.2);
      }

      h2 {
        font-weight: normal;
        margin-bottom: 0;
        color: #fff;
      }
    }

    footer {
      padding: 6em 0.5em;
      text-align: center;

      position: absolute;
      bottom: -20px;
      width: 100%;
      height: 1rem;

      img {
        width: 180px;
      }
    }

    ul {
      list-style: none;
      /* padding: 0.5em 0; */
      margin: 0;

      a {
        text-decoration: none;
      }

      button {
        /* margin-left: auto; */
        display: flex;
        align-items: center;

        background: transparent;
        border: 0;

        svg {
          color: #999591;
          width: 20px;
          height: 20px;
        }
      }

      li {
        display: flex;
        align-items: center;

        padding: 1em 1em 0.5em 3em;
        font-size: 0.65em;
        font-weight: regular;
        background-repeat: no-repeat;
        background-position: left 15px center;
        background-size: auto 20px;
        transition: all 0.15s linear;
        cursor: pointer;

        span {
          color: #fff;
          font-family: 'Roboto Slab', serif;
          font-size: 16px;
        }

        svg {
          height: 20px;
          width: 20px;
          color: #f7f5e6;
          margin-right: 8px;
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
`;
