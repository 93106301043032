import React, { useState, useEffect, useCallback } from 'react';

import { FiUserCheck, FiUserX, } from 'react-icons/fi';

import {
  Container,
  Content,
  ContentArea,
  Section,
  UserItemList,
  Avatar,
} from './styles';

import api from '../../services/api';

import ConfigurationMenu from '../../components/ConfigurationMenu';

interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  avatar_url: string;
  active: boolean;
}

const ManageUserList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    api.get('/users/all').then((response) => {
      const usersFormatted = response.data.map(
        (user: User) => {
          return {
            ...user,
            avatar_url: user ? `https://app-gobarberamx.s3.amazonaws.com/${user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
          };
        },
      );

      setUsers(usersFormatted);
    });
  }, []);

  const handleInactivateUser = useCallback(
    async (user: User) => {
      // eslint-disable-next-line no-restricted-globals
      const c = confirm(`Deseja ${user.active ? `inativar` : `ativar`}  ${user.name}?`);
      if (c === true) {

        await api.put('/users/active', {
          user_id: user.id,
          active: user.active ? false : true,
        });

        api.get('/users/all').then((response) => {
          const usersFormatted = response.data.map(
            (user: User) => {
              return {
                ...user,
                avatar_url: user ? `https://app-gobarberamx.s3.amazonaws.com/${user.avatar}` : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png',
              };
            },
          );
    
          setUsers(usersFormatted);
        });
      }
    },
    [],
  );


  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Listagem de Usuários</h1>

          <Section>
            {users.map((selectedUser) => (
              <UserItemList key={selectedUser.id}>
                <Avatar>
                  {selectedUser ? (
                    <img src={selectedUser.avatar_url} alt={selectedUser.name} title={selectedUser.name} />
                  ) : (
                    <img
                      src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png"
                      alt="avatar"
                    />
                  )}
                  <div>
                    <strong>{selectedUser.name}</strong>
                  </div>
                </Avatar>
                
                <div>
                {selectedUser.active ? (
                    <FiUserX
                      size={24}
                      title="Inativar"
                      onClick={() => {
                        selectedUser.id && handleInactivateUser(selectedUser);
                      }}
                    />
                  ) : (
                    <FiUserCheck
                      size={24}
                      title="Ativar"
                      onClick={() => {
                        selectedUser.id && handleInactivateUser(selectedUser);
                      }}
                    />
                  )}
                </div>
              </UserItemList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ManageUserList;
