/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Link, useLocation } from 'react-router-dom';

import { FiXCircle, FiPlusSquare, FiArrowLeft, FiEdit3 } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  Container,
  Content,
  ContentArea,
  FormContainerHeader,
  FormContainer,
  Section,
  ProcessClient,
} from './styles';

import api from '../../services/api';

import LeftMenu from '../../components/LeftMenu';
import InputWithLabel from '../../components/InputWithLabel';
import Select from '../../components/Select';
import Button from '../../components/Button';

interface Competence {
  id: string;
  name: string;
}

interface ProcessType {
  id: string;
  name: string;
}

interface ProcessFormData {
  folder: string;
  process_type?: string;
  competence?: string;
  process_number: string;
}

interface ClientProcess {
  id: string;
  // client_id: string;
  folder: string | undefined;
  process_type_id: string | undefined;
  competence_id: string | undefined;
  process_number: string | undefined;
  processType: ProcessType | undefined;
  competence: Competence | undefined;
}

interface Client {
  id: string;
  name: string;
}

interface Location {
  selectedClient: Client;
}

const Process: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();

  let selectedClient: Client = location.state as Client;

  if (!selectedClient.id) {
    const locationState: Location = location.state as Location;
    selectedClient = locationState.selectedClient;
  }

  const [competences, setCompetences] = useState<Competence[]>([]);
  const [processTypes, setProcessTypes] = useState<ProcessType[]>([]);
  const [clientProcess, setClientProcess] = useState<ClientProcess[]>([]);
  const [
    selectedClientProcess,
    setSelectedClientProcess,
  ] = useState<ClientProcess>();

  useEffect(() => {
    api.get('/competences').then((response) => {
      setCompetences(response.data);
    });
  }, []);

  useEffect(() => {
    api.get('/process-types').then((response) => {
      setProcessTypes(response.data);
    });
  }, []);

  useEffect(() => {
    if (selectedClient.id) {
      api
        .get(`/client-process/client/${selectedClient.id}`)
        .then((response) => {
          setClientProcess(response.data);
        });
    }
  }, [selectedClient]);

  console.log('processos', clientProcess);

  const handleAddProcess = useCallback(
    async (data: ProcessFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          folder: Yup.string().required('Pasta obrigatória'),
          process_type: Yup.string().required('Tipo do Processo obrigatório'),
          // competence: Yup.string().required('Competência obrigatória'),
          // process_number: Yup.string().required(
          //   'Número do Processo obrigatório',
          // ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const processType = processTypes.find(
          (pType) => pType.id === data.process_type,
        );
        const competence = competences.find(
          (comp) => comp.id === data.competence,
        );

        console.log('selectedclientprocess', selectedClientProcess);

        if (!selectedClientProcess) {
          await api.post('/client-process', {
            client_id: selectedClient.id,
            folder: data.folder,
            process_type_id: processType?.id,
            competence_id: competence?.id,
            process_number: data.process_number,
          });

          setSelectedClientProcess(undefined);

          await api
            .get(`/client-process/client/${selectedClient.id}`)
            .then((response) => {
              setClientProcess(response.data);
            });

          /*
          setClientProcess([
            ...clientProcess,
            {
              id: process.data.id || '',
              competence,
              competence_id: competence?.id || '',
              process_type_id: processType?.id || '',
              folder: data.folder || '',
              process_number: data.process_number,
              processType,
            },
          ]); */
        } else {
          await api.put('/client-process', {
            id: selectedClientProcess?.id,
            client_id: selectedClient.id,
            folder: data.folder,
            process_type_id: processType?.id,
            competence_id: competence !== null ? competence?.id : null,
            process_number: data.process_number,
          });

          setSelectedClientProcess(undefined);

          await api
            .get(`/client-process/client/${selectedClient.id}`)
            .then((response) => {
              setClientProcess(response.data);
            });
        }

        formRef.current?.reset();
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [competences, processTypes, selectedClient.id, selectedClientProcess],
  );

  const handleDeleteProcess = useCallback(async (processId: string) => {
    // eslint-disable-next-line no-restricted-globals
    const c = confirm('Deseja excluir?');
    if (c === true) {
      await api.delete(`/client-process/${processId}`).then((response) => {
        setClientProcess((proc) =>
          proc.filter((_proc) => _proc.id !== processId),
        );
      });
    }
  }, []);

  const handleLoadValuesToFields = useCallback(
    (client_process: ClientProcess) => {
      formRef.current?.reset();

      setSelectedClientProcess(client_process);

      formRef.current?.setData({
        folder: client_process.folder,
      });
      formRef.current?.setData({
        process_type: client_process.process_type_id,
      });
      formRef.current?.setData({
        competence: client_process.competence_id,
      });
      formRef.current?.setData({
        process_number: client_process.process_number,
      });
    },
    [],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h2>
            <b>{selectedClient.name}</b>
          </h2>
          <h3>Processos do cliente</h3>

          <Form ref={formRef} onSubmit={handleAddProcess}>
            <FormContainer>
              <InputWithLabel name="folder" label="Pasta" />

              <Select
                name="process_type"
                label="Tipo do processo"
                initialLabel="Selecione o tipo do processo"
                options={processTypes.map((processType) => ({
                  value: processType.id,
                  label: processType.name,
                }))}
              />

              <Select
                name="competence"
                label="Competência"
                initialLabel="Selecione a competência"
                options={competences.map((competence) => ({
                  value: competence.id,
                  label: competence.name,
                }))}
              />

              <InputWithLabel name="process_number" label="Nº do processo" />

              <Button type="submit">Salvar</Button>
            </FormContainer>
            <FormContainer>
              <Link
                to={{
                  pathname: '/update-client',
                  state: { selectedClient },
                }}
              >
                <FiArrowLeft size={30} title="Cadastro do Cliente" />
              </Link>
            </FormContainer>

            <FormContainerHeader>
              <strong>Pasta</strong>
              <strong>Tipo do Processo</strong>
              <strong>Competência</strong>
              <strong>Nº do Processo</strong>
            </FormContainerHeader>

            <Section>
              {clientProcess.map((process) => (
                <ProcessClient key={process.id}>
                  <div>
                    <strong>{process.folder}</strong>
                  </div>

                  <div>
                    <strong>
                      {process.processType && process.processType.name}
                    </strong>
                  </div>

                  <div>
                    <strong>
                      {process.competence && process.competence.name}
                    </strong>
                  </div>

                  <div className='child_div'>
                    &nbsp;&nbsp;
                    <strong>
                      {process.process_number
                        ? process.process_number
                        : 'Não informado'}
                    </strong>

                    <div>
                      <FiEdit3
                        title="Editar"
                        onClick={() => {
                          process.folder && handleLoadValuesToFields(process);
                        }}
                      />
                      <FiXCircle
                        onClick={() => {
                          process.id && handleDeleteProcess(process.id);
                        }}
                        title="Excluir"
                      />
                      <Link
                        to={{
                          pathname: '/process-attendance',
                          state: { process, selectedClient },
                        }}
                        title="Andamento do processo"
                      >
                        <FiPlusSquare />
                      </Link>
                    </div>

                    
                    
                  </div>
                </ProcessClient>
              ))}
            </Section>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default Process;
