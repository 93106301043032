/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect, useState } from 'react';

import { FiSearch } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import {
  Container,
  Content,
  ContentArea,
  FormContainer,
  RadioContainer,
} from './styles';

import api from '../../../services/api';

import UtilMenu from '../../../components/UtilMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';

interface ContactClientFormData {
  attendance_date: string;
  folder: string;
  description: string;
  receipt_value: string;
  client_name: string;
}

interface Client {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  process_number: string;
  client: Client;
}

interface ReceiptData {
  locationState: {
    receiptData: {
      client: string;
      receipt_value: string;
      documents: string;
      clientProcess: ClientProcess;
      folder: string;
      displayType: string;
    };
  };
}

const ValueReceipt: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [clientProcess, setClientProcess] = useState<ClientProcess>();
  const [displayType, setDisplayType] = useState<string>();
  const [displayTypeDivName, setDisplayTypeDivName] = useState<string>();

  const locationState: ReceiptData = location.state as ReceiptData;

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    setDisplayType('block');
    setDisplayTypeDivName('none');

    if (locationState) {
      if (locationState.locationState.receiptData.displayType === 'block') {
        setDisplayTypeDivName('none');
        setDisplayType(locationState.locationState.receiptData.displayType);
        formRef.current?.setData({
          description: locationState.locationState.receiptData.documents,
          receipt_value: locationState.locationState.receiptData.receipt_value,
          folder: locationState.locationState.receiptData.folder,
        });
        setClientProcess(locationState.locationState.receiptData.clientProcess);
      }
      if (locationState.locationState.receiptData.displayType === 'none') {
        setDisplayTypeDivName('block');
        setDisplayType(locationState.locationState.receiptData.displayType);
        formRef.current?.setData({
          description: locationState.locationState.receiptData.documents,
          receipt_value: locationState.locationState.receiptData.receipt_value,
          client_name: locationState.locationState.receiptData.client,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = useCallback(
    async (data: ContactClientFormData) => {
      try {
        formRef.current?.setErrors({});

        if (displayType === 'block') {
          const schema = Yup.object().shape({
            folder: Yup.string().required('Pasta obrigatória'),
            receipt_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (clientProcess) {
            const receiptData = {
              client: clientProcess.client.name,
              documents: data.description,
              receipt_value: data.receipt_value,
              clientProcess,
              folder: data.folder,
              displayType,
            };
            history.push({
              pathname: '/value-receipt-pdf',
              state: {
                receiptData,
              },
            });
          } else {
            addToast({
              type: 'error',
              title: 'É preciso carregar o processo do cliente',
              description: 'Digite a pasta e clique na lupa.',
            });
          }
        }
        if (displayType === 'none') {
          formRef.current?.setErrors({});

          const schema = Yup.object().shape({
            client_name: Yup.string().required('Cliente obrigatório'),
            receipt_value: Yup.string().required('Valor obrigatório'),
          });

          await schema.validate(data, {
            abortEarly: false,
          });

          if (data.client_name) {
            const receiptData = {
              client: data.client_name,
              documents: data.description,
              receipt_value: data.receipt_value,
              displayType,
            };
            history.push({
              pathname: '/value-receipt-pdf',
              state: {
                receiptData,
              },
            });
          } else {
            addToast({
              type: 'error',
              title: 'É preciso informar cliente',
            });
          }
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na geração do recibo',
          description: 'Ocorreu um erro ao gerar recibo de documento.',
        });
      }
    },
    [addToast, clientProcess, displayType, history],
  );

  const handleFindByFolder = useCallback(() => {
    const folder = formRef.current?.getFieldValue('folder');
    if (folder) {
      api.get(`/client-process/folder/${folder}`).then((response) => {
        if (response.data) {
          setClientProcess(response.data);
        } else {
          setClientProcess(undefined);
          addToast({
            type: 'info',
            title: 'Pasta não encontrada',
            description: 'Não foi possível localizar a pasta informada.',
          });
        }
      });
    }
  }, [addToast]);

  const handleRadioWithFolder = useCallback(() => {
    // alert('Com pasta');
    setDisplayType('block');
    setDisplayTypeDivName('none');
  }, []);

  const handleRadioNoFolder = useCallback(() => {
    // alert('Sem pasta');
    setDisplayType('none');
    setDisplayTypeDivName('block');
  }, []);

  return (
    <Container>
      <Content>
        <UtilMenu />
        <ContentArea>
          <h1>Recibo de Valores</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <RadioContainer>
              <input
                type="radio"
                id="withfolder"
                name="kind"
                value="withfolder"
                checked={displayType === 'block'}
                onChange={handleRadioWithFolder}
              />
              <label htmlFor="withfolder">Com Pasta</label>
              <br />
              <input
                type="radio"
                id="nofolder"
                name="kind"
                value="nofolder"
                checked={displayType === 'none'}
                onChange={handleRadioNoFolder}
              />
              <label htmlFor="nofolder">Sem Pasta</label>
            </RadioContainer>

            <div style={{ display: displayType }}>
              <FormContainer>
                <InputWithLabel name="folder" label="Pasta" type="text" />
                <Button
                  type="button"
                  title="Dados do cliente"
                  onClick={handleFindByFolder}
                >
                  <FiSearch size={30} />
                </Button>
                <label className="process-client">
                  {clientProcess && clientProcess?.client.name}{' '}
                  {clientProcess && ' - Processo: '}
                  {clientProcess && clientProcess.process_number}
                </label>
              </FormContainer>
            </div>

            <div style={{ display: displayTypeDivName }}>
              <FormContainer>
                <InputWithLabel name="client_name" label="Nome" type="text" />
              </FormContainer>
            </div>

            <FormContainer>
              <InputWithLabel
                name="receipt_value"
                label="Valor"
                type="number"
                min="0"
                step="0.01"
                data-number-to-fixed="2"
                data-number-stepfactor="100"
              />
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Rererente a"
                rows={12}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Gerar Recibo</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ValueReceipt;
