import React, { useState, useEffect, useCallback } from 'react';

import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import { FiXCircle } from 'react-icons/fi';
import api from '../../services/api';

import {
  Container,
  Content,
  ContentArea,
  Section,
  ProcessClient,
  FormContainerHeader,
} from './styles';

import ConfigurationMenu from '../../components/ConfigurationMenu';

interface Client {
  id: string;
  name: string;
}

interface LocationState {
  client: Client;
}

interface Competence {
  id: string;
  name: string;
}

interface ProcessType {
  id: string;
  name: string;
}

interface ClientProcess {
  id: string;
  folder: string | undefined;
  process_type_id: string | undefined;
  competence_id: string | undefined;
  process_number: string | undefined;
  processType: ProcessType | undefined;
  competence: Competence | undefined;
}

const InactivateClient: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  const locationState: LocationState = location.state as LocationState;
  const [clientProcess, setClientProcess] = useState<ClientProcess[]>([]);
  const [clientName, setClientName] = useState<string>('');
  const [client, setClient] = useState<Client>();

  console.log('location', locationState.client);
  // setClientName(locationState.client.name);

  useEffect(() => {
    if (locationState.client.id) {
      setClientName(locationState.client.name);
      setClient(locationState.client);
      api
        .get(`/client-process/client/${locationState.client.id}`)
        .then((response) => {
          console.log(response.data);
          setClientProcess(response.data);
        });
    }
  }, [locationState.client]);

  const handleInactivateProcess = useCallback(
    async (process: ClientProcess) => {
      history.push('/inactivate-process', { process, client });
    },
    [client, history],
  );

  return (
    <Container>
      <Content>
        <ConfigurationMenu />
        <ContentArea>
          <h1>Inativar Processo</h1>
          <br />
          <h2>{clientName}</h2>

          <FormContainerHeader>
            <strong>Pasta</strong>
            <strong>Tipo do Processo</strong>
            <strong>Competência</strong>
            <strong>Nº do Processo</strong>
          </FormContainerHeader>

          <Section>
            {clientProcess.map((process) => (
              <ProcessClient key={process.id}>
                <div>
                  <strong>{process.folder}</strong>
                </div>

                <div>
                  <strong>
                    {process.processType && process.processType.name}
                  </strong>
                </div>

                <div>
                  <strong>
                    {process.competence && process.competence.name}
                  </strong>
                </div>

                <div>
                  &nbsp;&nbsp;
                  <strong>
                    {process.process_number
                      ? process.process_number
                      : 'Não informado'}
                  </strong>
                  <FiXCircle
                    onClick={() => {
                      process.id && handleInactivateProcess(process);
                    }}
                    title="Inativar"
                  />
                </div>
              </ProcessClient>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default InactivateClient;
