import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.main`
  /* max-width: 1120px; */
  margin-right: 80px;
  display: flex;
`;

export const ContentArea = styled.main`
  /* position: absolute; */
  height: 100vh;

  margin-left: 250px;

  h1 {
    margin-top: 40px;
  }
`;

export const FormContainer = styled.div`
  margin-top: 0;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  /* input {
    text-transform: uppercase;
  } */

  a {
    text-decoration: none;
    color: #323a55;
  }

  label {
    cursor: pointer;
  }

  .birthDiv {
    margin-top: 20px;
  }
`;

export const ObservationContainer = styled.div`
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 4fr;
`;
