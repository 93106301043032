/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer } from './styles';

import UtilMenu from '../../../components/UtilMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';

interface ContactClientFormData {
  attendance_date: string;
  name: string;
  description: string;
  receipt_value: string;
}

interface ReceiptData {
  locationState: {
    receiptData: {
      name: string;
      receipt_value: string;
      documents: string;
    };
  };
}

const ValueReceiptNoFolder: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const locationState: ReceiptData = location.state as ReceiptData;

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (locationState) {
      formRef.current?.setData({
        description: locationState.locationState.receiptData.documents,
        receipt_value: locationState.locationState.receiptData.receipt_value,
        name: locationState.locationState.receiptData.name,
      });
    }
  }, [locationState]);

  const handleSubmit = useCallback(
    async (data: ContactClientFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Cliente obrigatório'),
          receipt_value: Yup.string().required('Valor obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.name) {
          const receiptData = {
            documents: data.description,
            receipt_value: data.receipt_value,
            name: data.name,
          };

          history.push({
            pathname: '/value-receipt-pdf-no-folder',
            state: {
              receiptData,
            },
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso informar cliente',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na geração do recibo',
          description: 'Ocorreu um erro ao gerar recibo de documento.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Content>
        <UtilMenu />
        <ContentArea>
          <h1>Recibo de Valores</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <InputWithLabel name="name" label="Cliente" type="text" />
            </FormContainer>

            <FormContainer>
              <InputWithLabel
                name="receipt_value"
                label="Valor"
                type="number"
                min="0"
                step="0.01"
                data-number-to-fixed="2"
                data-number-stepfactor="100"
              />
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Rererente a"
                rows={12}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Gerar Recibo</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default ValueReceiptNoFolder;
