/* eslint-disable no-plusplus */
import React, { useRef, useCallback, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/toast';

import getValidationErrors from '../../../utils/getValidationErrors';

import { Container, Content, ContentArea, FormContainer } from './styles';

import UtilMenu from '../../../components/UtilMenu';
import InputWithLabel from '../../../components/InputWithLabel';
import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';

interface ContactClientFormData {
  attendance_date: string;
  name: string;
  description: string;
}

interface ReceiptData {
  locationState: {
    receiptData: {
      name: string;
      documents: string;
    };
  };
}

interface Client {
  id: string;
  name: string;
}

const DocumentReceiptNoFolder: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const locationState: ReceiptData = location.state as ReceiptData;
  console.log('state', locationState);
  // if (locationState.locationState.receiptData) {
  //   alert(locationState.locationState.receiptData.documents);
  // }

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (locationState) {
      formRef.current?.setData({
        description: locationState.locationState.receiptData.documents,
        name: locationState.locationState.receiptData.name,
      });
    }
  }, [locationState]);

  const handleSubmit = useCallback(
    async (data: ContactClientFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Cliente obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (data.name) {
          const receiptData = {
            name: data.name,
            documents: data.description,
          };

          history.push({
            pathname: '/document-receipt-pdf-no-folder',
            state: {
              receiptData,
            },
          });
        } else {
          addToast({
            type: 'error',
            title: 'É preciso informar o nome do cliente',
          });
        }
      } catch (err) {
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na geração do recibo',
          description: 'Ocorreu um erro ao gerar recibo de documento.',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Content>
        <UtilMenu />
        <ContentArea>
          <h1>Recibo de Documentos</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <FormContainer>
              <InputWithLabel name="name" label="Cliente" type="text" />
            </FormContainer>

            <FormContainer>
              <TextArea
                name="description"
                label="Rererente a"
                rows={12}
                cols={100}
              />
            </FormContainer>

            <Button type="submit">Gerar Recibo</Button>
          </Form>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default DocumentReceiptNoFolder;
