import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const Content = styled.main`
  display: flex;
  flex: 1;
`;

export const ContentArea = styled.main`
  flex: 1;

  height: 100vh;

  margin-left: 250px;
  margin-right: 50px;

  h1 {
    margin-top: 40px;
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`;

export const FormContainer = styled.div`
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  a {
    text-decoration: none;
    color: #323a55;
    font-size: 20px;

    &:hover {
      font-weight: 700;
    }
  }

  label {
    cursor: pointer;
  }

  .birthDiv {
    margin-top: 20px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-top: 16px;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    border-radius: 10px;

    button {
      margin: 0;
      width: 60px;
      text-align: center;
    }
  }
`;

export const UserItemList = styled.div`
  display: flex;
  align-items: center;

  border-bottom: 1px solid #2d344c;

  & + div {
    margin-top: 5px;
  }

  span {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #f4ede8;
    width: 70px;
  }

  div {
    flex: 1;
    background: #e8e8e8;
    display: flex;
    align-items: center;
    padding: 16px 8px;

    strong {
      margin-left: 3px;
      color: #323a55;
      font-size: 20px;
    }

    svg {
      color: #ff9000;
      margin-left: 24px;
      cursor: pointer;
    }
  }
`;

export const Avatar = styled.div`
  // margin-bottom: 5px;
  position: relative;
  align-self: center;
  
  img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  
`;