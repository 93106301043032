import React, { useState, useEffect, useCallback } from 'react';

import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Content,
  ContentArea,
  Section,
  ListKindItemList,
} from './styles';

import LeftMenu from '../../components/LeftMenu';

interface ListKind {
  id: number;
  name: string;
}

const List: React.FC = () => {
  const [lists, setLists] = useState<ListKind[]>([]);
  const history = useHistory();

  useEffect(() => {
    setLists([
      ...lists,
      { id: 1, name: 'Implantação Judicial' },
      { id: 2, name: 'Contactar Clientes' },
      { id: 3, name: 'Prazos' },
      { id: 4, name: 'Implantação Administrativa' },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewList = useCallback(
    (item: ListKind) => {
      if (item.name === 'Implantação Judicial') {
        history.push('/judicial-implantation-list');
      }
      if (item.name === 'Contactar Clientes') {
        history.push('/contact-client-list');
      }
      if (item.name === 'Prazos') {
        history.push('/intimation');
      }
      if (item.name === 'Implantação Administrativa') {
        history.push('/admin-implantation-list');
      }
    },
    [history],
  );

  return (
    <Container>
      <Content>
        <LeftMenu />
        <ContentArea>
          <h1>Listas</h1>

          <Section>
            {lists.map((selectedListKind) => (
              <ListKindItemList key={selectedListKind.id}>
                <div>
                  <strong>{selectedListKind.name}</strong>
                </div>

                <div>
                  <FiEye
                    title="Visualizar"
                    onClick={() => {
                      selectedListKind.id && handleViewList(selectedListKind);
                    }}
                  />
                </div>
              </ListKindItemList>
            ))}
          </Section>
        </ContentArea>
      </Content>
    </Container>
  );
};

export default List;
