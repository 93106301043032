import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  customTooltip: {
    fontSize: '18px',
  },
}));

interface TableColumnProps {
  width?: string;
  textAlign?: string;
}

export const Container = styled.div``;

export const Header = styled.header`
  padding: 32px 0;
  background: #28262e;
`;

export const Content = styled.main`
  display: flex;
  flex: 1;
`;

export const ContentArea = styled.main`
  flex: 1;

  height: 100vh;

  margin-left: 250px;
  margin-right: 50px;

  h1 {
    margin-top: 40px;
  }
`;

export const DeadlineContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;

  margin-top: 30px;

  div {
    width: 60%;
  }

  .hideDiv {
    display: none;
  }

  .showDiv {
    display: block;
  }

  button {
    margin: 0;
    margin-top: 5px;
    width: 280px;
    text-align: center;
    margin-right: 8px;
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`;

export const FormContainer = styled.div`
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1.6rem;
  row-gap: 1.6rem;

  a {
    text-decoration: none;
    color: #323a55;
    font-size: 20px;

    &:hover {
      font-weight: 700;
    }
  }

  label {
    cursor: pointer;
  }

  .birthDiv {
    margin-top: 20px;
  }
`;

export const FormContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & + div {
    margin-top: 16px;
  }

  div {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 16px 16px;
    border-radius: 10px;

    button {
      margin: 0;
      width: 60px;
      text-align: center;
    }
  }
`;

export const ImplantationList = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  border-bottom: 1px solid #2d344c;

  & + div {
    margin-top: 5px;
  }

  span {
    margin-left: auto;
    display: flex;
    align-items: center;
    color: #f4ede8;
    width: 70px;
  }

  div {
    flex: 1;
    background: #e8e8e8;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    max-width: 300px;

    strong {
      margin-left: 3px;
      color: #323a55;
      font-size: 16px;

      max-width: 150px; // Defina a largura máxima desejada
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .expiredDeadline {
      margin-left: 3px;
      color: #ff0000;
      font-size: 16px;
    }

    svg {
      color: #ff9000;
      margin-left: 24px;
      cursor: pointer;
    }
  }
`;

export const Avatar = styled.div`
  // margin-bottom: 5px;
  position: relative;
  align-self: center;
  
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .MuiTooltip-tooltip {
    font-size: 16px !important; /* Ajuste o tamanho da fonte conforme necessário */
  }
`;

export const FlexHeaderTable = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
`;

export const TableColumn = styled.div<TableColumnProps>`
  flex: ${props => props.width || '1'}; /* A largura da coluna pode ser especificada como uma propriedade ou será igualmente dividida se não for fornecida */
  padding: 8px;
  text-align: ${props => props.textAlign || 'start'};
  display: flex;
  align-items: center;

  // white-space: nowrap; /* Impede que o conteúdo quebre para a próxima linha */
  
  min-width: 80px; /* Largura mínima quando não houver conteúdo */

  strong {
    margin-left: 3px;
    color: #323a55;
    font-size: 16px;
    display: block;

    max-width: 200px; // Defina a largura máxima desejada
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }

  .expiredDeadline {
    margin-left: 3px;
    color: #ff0000;
    font-size: 16px;
  }

  svg {
    color: #ff9000;
    margin-left: 18px;
    cursor: pointer;
  }

  div {
    flex: 1;
    display: flex;
    word-wrap: break-word;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc; 
`;